import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowBackIcon = (props) => {
  const iconWidth = props?.width || 9;
  const iconHeight = props?.height || 9;
  const color = props?.color || '#273238'
  return (
    <Icon 
      viewBox="0 0 9 9" 
      color={color} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path d="M2.15325 3.92144L9 3.92144L9 5.07855L2.15325 5.07855L5.1705 8.18192L4.37512 9L-1.96701e-07 4.5L4.37512 -1.91243e-07L5.1705 0.818076L2.15325 3.92144Z" 
            fill="currentColor"/>

    </Icon>
  );
};
export default ArrowBackIcon;