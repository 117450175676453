

import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchDentistDetailsRoles } from './services';
import { toString } from 'lodash';

export const QUERY_KEY_NAME = 'Admin_DentistDetailsRoles';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (dentistId) => {
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    //const searchParams = new URLSearchParams(paramsObj)
    return fetchDentistDetailsRoles(dentistId)
}

export const useDentistDetailsRoles = ({ dentistId }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!dentistId,
        queryKey: [QUERY_KEY_NAME, {id: toString(dentistId)}],
        queryFn: () => queryRequest(dentistId), // in brackets because im parsing the object in queryRequest
      });
}