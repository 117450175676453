import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { services } from '../_redux/services';
import { toString } from 'lodash';

export const QUERY_KEY_NAME = "PATIENT_RECORD_DETAILS";

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    },
    refetchOnMount: false
}

export const queryPatientRecord = async ({ recordUid, clinic }) => {
    // filter through the input params and remove any empty values
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    // format the params object into a URLSearchParams object
    return services.fetchRecordByUid(recordUid, clinic);
}

export const usePatientRecord = ({ recordUid, clinic }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!recordUid,
        queryKey: [QUERY_KEY_NAME, {id: toString(recordUid)}],
        queryFn: () => queryPatientRecord({recordUid, clinic}),
      });
}