import React from 'react';
import {
    Text, Textarea, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Center, Button
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';

export const ConfirmationDialog = ({ confirmType, isOpen, onClose, mutationFunction, onSuccess  }) => {
    const [ error, setError ] = React.useState(null);
    const [ reason, setReason ] = React.useState("");
    const { formatMessage } = useIntl();

    const description = {
        'archive': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.archive.description',
            defaultMessage: 'Are you sure you want to archive the treatment plan?'
        }),
        'complete': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.complete.description',
            defaultMessage: 'Are you sure you want to confirm completion the treatment plan?'
        }),
        'reject': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.reject.description',
            defaultMessage: 'Are you sure you want to reject the treatment plan?'
        }),
        'cancel': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.cancel.description',
            defaultMessage: 'Are you sure you want to cancel the treatment plan?'
        }),
        'start': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.start.description',
            defaultMessage: 'Are you sure you want to start the treatment plan?'
        })
    }

    const title = {
        'archive': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.archive.title',
            defaultMessage: 'Archive Treatment Plan?'
        }),
        'complete': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.complete.title',
            defaultMessage: 'Complete Treatment Plan?'
        }),
        'reject': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.reject.title',
            defaultMessage: 'Reject Treatment Plan?'
        }),
        'cancel': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.cancel.title',
            defaultMessage: 'Cancel Treatment Plan?'
        }),
        'start': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.start.title',
            defaultMessage: 'Start Treatment Plan?'
        })
    }

    const confirmText = {
        'archive': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.archive.confirm',
            defaultMessage: 'Yes, Archive'
        }),
        'complete': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.complete.confirm',
            defaultMessage: 'Yes, Complete'
        }),
        'reject': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.reject.confirm',
            defaultMessage: 'Yes, Reject'
        }),
        'cancel': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.cancel.confirm',
            defaultMessage: 'Yes, Cancel'
        }),
        'start': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.start.confirm',
            defaultMessage: 'Yes, Start'
        })
    }

    const closeText = {
        'archive': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.archive.cancel',
            defaultMessage: 'No, Close'
        }),
        'complete': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.complete.cancel',
            defaultMessage: 'No, Close'
        }),
        'reject': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.reject.cancel',
            defaultMessage: 'No, Close'
        }),
        'cancel': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.cancel.cancel',
            defaultMessage: 'No, Close'
        }),
        'start': formatMessage({
            id: 'treatmentPlanDetails.confirmationDialog.start.cancel',
            defaultMessage: 'No, Close'
        })
    }
  
    const handleClose = () => {
      setError(null);
      setReason("");
      onClose();
    }

    const dataKey = `${confirmType}_reason`
  
    const handleConfirm = async () => {
        try{
            const resp = await mutationFunction.mutateAsync({[dataKey]: reason});  
            onSuccess && onSuccess(resp);
            handleClose();
        } catch (err){
            console.error(err);
            setError(err);
        }
    }
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        {title[confirmType]}
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <Text>
                        {description[confirmType]}
                    </Text>
                    <Textarea 
                      mt={'15px'}
                      borderRadius={'15px'}
                      placeholder={formatMessage({
                          id: 'treatmentPlanDetails.confirmationDialog.reason',
                          defaultMessage: 'Please provide a reason (optional)'
                      })}
                      value={reason} 
                      onChange={(event) => setReason(event.target.value)}
                    />
                </ModalBody>
                <Flex w={'full'} justify={'center'} align={'center'}>
                    {error && <Text color="red">{error.message}</Text>}
                </Flex>
                <ModalFooter pb={'20px'}>
                    <Button 
                        isDisabled={!mutationFunction}
                        isLoading={mutationFunction?.isLoading}
                        mr={3} 
                        onClick={handleConfirm}
                    >
                        {confirmText[confirmType]}
                    </Button>
                    <Button 
                        isDisabled={!mutationFunction || mutationFunction?.isLoading}
                        variant="outlined" 
                        onClick={handleClose}
                    >
                        {closeText[confirmType]}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
  }