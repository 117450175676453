import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchReportCategories } from './services';

export const QUERY_KEY_NAME = 'ReportCategories';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async ({ reportType, locale=null }) => {
    return fetchReportCategories(reportType, locale);
}

export const useReportCategories = ({ reportType, locale=null, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,

        queryKey: [QUERY_KEY_NAME, {reportType, locale}],
        queryFn: () => queryRequest({reportType, locale}),
      });
}