import { useMutation } from 'react-query';
import { QUERY_KEY_NAMES } from './constants';
import { queryClient } from 'src/_libs/react-query';

import { createReportForRequest, updateReport } from './services';
import { toString } from 'lodash';

const updateReportApi = async ({ reportUid, data, clinic }) => {
    return updateReport(reportUid, data, clinic);
}

const createReportApi = async ({ requestUid, data, clinic }) => {
    return createReportForRequest(requestUid, data, clinic);
}

const handleSuccess = async (reportUid, data) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {id: toString(reportUid)}], (oldData) => {
        return {
            report: {
                ...(oldData?.report || {}),
                ...data
            }
        }
    });
}

const handleError =  (reportUid) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {id: toString(reportUid)}], (previousData) => {
        if (!previousData?.report) {
            return undefined; // return blank
        }
        return {report: previousData.report};
    });
}

export const useSaveReport = ({ reportUid, requestUid, clinic }) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.SAVE_REPORT,
        onSuccess: (data) => handleSuccess(reportUid, data),
        onError: (error, context) => {handleError(reportUid, error, context)},
        mutationFn: (data) => {
            if (reportUid) {
                return updateReportApi({ reportUid, data, clinic });
            } else {
                return createReportApi({ requestUid, data, clinic });
            }
        },
    });
}