import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchPatientUserCollection } from './services';

export const QUERY_KEY_NAME = 'Admin_PatientUserCollection';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (queryParams) => {
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    const searchParams = new URLSearchParams(paramsObj)
    return fetchPatientUserCollection(searchParams)
}

export const usePatientUserCollection = ({ type="", orderBy="", offset=0, pg_sz=10 }) => {
    return useQuery({
        ...newQueryConfig,
        queryKey: [QUERY_KEY_NAME, {type, orderBy, offset, pg_sz}],
        queryFn: () => queryRequest({type, orderBy, offset, pg_sz}), // in brackets because im parsing the object in queryRequest
      });
}