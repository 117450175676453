import React, { useRef, useState, useEffect} from 'react';
import { formatName } from '../_helpers'
import { useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';
import { Link, useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Flex, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';

import { upperFirst, startCase, toUpper, capitalize} from 'lodash';

import { IoLanguageSharp } from 'react-icons/io5';
import { GrNewWindow } from 'react-icons/gr';

import { FormattedMessage } from 'react-intl';

// components
import { LoadingEllipsis } from '../_components/Loaders';
import { Authentication } from './Authentication';
import { Notifications } from './Notifications';
import { Profile } from './Profile';
import { Booking } from './Booking';
//import { ChannelService } from '../ChannelIO';

import { DownloadReport } from 'src/Reports/components/BulkReportDownload';

// actions
import { actions as authActions } from '../App/_redux/actions';
import { services as authServices } from '../App/_redux/services';

// services
import { services as mediaUploadServices } from '../MediaSection/_redux/services';

// selector
import {
  getIntlMessages,
  //getUUID
} from '../App/_redux/selectors';

// styles
import { InitialsAvatar } from '../_css/styles';
import {
  StyledMenuHeader,
  StyledMenuItem,
  StyledMenuList,
  StyledMenuContent,
  StyledSettingsPage,
  StyledEditAvatar,
  StyledProfileAvatar
} from './styles';
import { consoleLog } from '../_helpers/consoleLog';
import { debounceClick } from 'src/_helpers/debounceClick';
import { SwitchLanguageModal } from './SwitchLanguageModal';
import { useDentistBilling } from './api/getClinicBilling';
import { useAuth } from 'src/_libs/auth';
import { queryClient } from 'src/_libs/react-query';
import ArrowBackIcon from 'src/_images/icons/ArrowBackIcon';

const EditAvatar = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const [ currentFile, setCurrentFile ] = useState(null);
  const [ progress, setProgress ] = useState(0);
  const [ error, setError] = useState(null)

  const inputRef = useRef(null);

  useEffect(()=>{
    if (currentFile){

      mediaUploadServices.uploadAvatar(currentFile, (event) => {
        setProgress(Math.round((95 * event.loaded) / event.total));
      }).then(
        resp => {
          setProgress(0);
          setCurrentFile(null);
          let data = {key: 'profile', value: {avatar:resp.data.avatar}}
          consoleLog("seinding ", resp.data)
          consoleLog("seinding ", resp.data.avatar)
          dispatch(authActions.updateObject(data))
        }
      ).catch(
        error => {
          setError(error);
          setProgress(0);
        }
      )

    }
  }, [currentFile] )

  const onFileUpload = async () => {
    if (progress == 0){
      inputRef.current.click();
    }
  }

  const onFileChange = (event) => {
    setCurrentFile(event.target.files[0]);
  }

  return(
    <StyledEditAvatar progress={progress}>
      <input ref={inputRef} type="file" onChange={onFileChange} />
      { progress > 0
        ? <LoadingEllipsis width={'100%'} height={'20px'}/>
      : <div onClick={onFileUpload}>{toUpper(intlMessages['settingsPage.menuheader.avatar.edit.label'])}</div>
      }
      { error && <></>}

    </StyledEditAvatar>
  )
}

const ProfileAvatar = ({src, initials}) => {
  if (src && src.length > 0){
    return (
      <StyledProfileAvatar>
        <img src={src} />
      </StyledProfileAvatar>
    )
  } else {
    return (
      <StyledProfileAvatar>
        <InitialsAvatar>
          {initials}
        </InitialsAvatar>
      </StyledProfileAvatar>
    )
  }
}

export const MenuItem = ({selected, label, url, external}) => {
  if (external){
    return(
      <StyledMenuItem selected={selected}>
        <a target="_blank" rel="noreferrer" href={url}>
          {label}
        </a>
        <GrNewWindow />
      </StyledMenuItem>
    )
  } else {
    return(
      <StyledMenuItem selected={selected}>
        <Link to={url}>
          {label}
        </Link>
      </StyledMenuItem>
    )
  }
}

export const LanguageMenuItem = ({selected, label, onClick}) => {
  return(
    <StyledMenuItem selected={selected}>
      <Link to={'#'} onClick={onClick}>
        <Flex align={['center']} justify={'space-between'} pr={['20px']}>
          <Text>
            {label}
          </Text>
          <IoLanguageSharp />
        </Flex>
      </Link>
    </StyledMenuItem>
  )
}

const ContactUsMenuItem = ({selected, label}) => {
  const { user } = useAuth();
  const userCountry = user?.country;
  let url = "mailto:support@pdhprotocols.com"
  if (userCountry == "TH"){
    url = "mailto:support-th@pdhprotocols.com"
  } else if (userCountry == "JP"){
    url = "mailto:support-jp@pdhprotocols.com"
  }
  return(
    <StyledMenuItem selected={selected}>
      <a target="_blank" rel="noreferrer" href={url}>
        {label}
      </a>
    </StyledMenuItem>
  )
}

export const MenuList = () => {
  const { user } = useAuth();
  let [loggedOut, setLoggedOut] = useState(false);
  let { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const billingQuery = useDentistBilling();
  //const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);

  const logout = async () => {
    if (!loggedOut){
      await authServices.logout();
      queryClient.invalidateQueries();
      setLoggedOut(loggedOut = true);
      history.replace("/login")
    }
  }


  return (
    <StyledMenuList>
      <div>
        <div className={'menuSubHeader'}>
          {startCase(intlMessages['settingsPage.menulist.subHeader.userSettings.title'])}
        </div>
        {/*<MenuItem
           selected={params.settingsId == 'booking'}
           label={upperFirst(intlMessages['settingsPage.menulist.menuitem.booking.label'])}
           url={'/settings/booking'}
        />*/}
        {
          (user?.apps || []).includes('open_requests') && (
            <MenuItem
              selected={params.settingsId == 'bulk_reports'}
              label={upperFirst(intlMessages['settingsPage.menulist.menuitem.bulk_reports.label'])}
              url={'/settings/bulk_reports'}
            />
          )
        }
        
        <MenuItem
          selected={params.settingsId == 'authentication'}
          label={upperFirst(intlMessages['settingsPage.menulist.menuitem.authentication.label'])}
          url={'/settings/authentication'}
        />
        <MenuItem
          selected={params.settingsId == 'notifications'}
          label={upperFirst(intlMessages['settingsPage.menulist.menuitem.notifications.label'])}
          url={'/settings/notifications'}
        />
        <LanguageMenuItem
          label={upperFirst(intlMessages['settingsPage.switchLanguage.title'])}
          onClick={onOpen}
        />
        {/*<MenuItem
           label={upperFirst(intlMessages['settingsPage.menulist.menuitem.billing.label'])}
           url={billingQuery?.data?.url}
        */}
        <SwitchLanguageModal isOpen={isOpen} onClose={onClose} />
      </div>

      <div>
        <div className={'menuSubHeader'}>
          {startCase(intlMessages['settingsPage.menulist.subHeader.aboutUs.title'])}
        </div>
        {/*<MenuItem
          label={upperFirst(intlMessages['settingsPage.menulist.menuitem.howtouse.label'])}
          url={'/settings/how-to'}
        />*/}
        <MenuItem
          label={upperFirst(intlMessages['settingsPage.menulist.menuitem.privacy.label'])}
          url={'https://pdhprotocols.com/privacy-policy'}
          external={true}
        />
        <MenuItem
          label={upperFirst(intlMessages['settingsPage.menulist.menuitem.termsofuse.label'])}
          url={'https://pdhprotocols.com/terms'}
          external={true}
        />
      </div>
      <div>
        <div className={'menuSubHeader'}>
          {startCase(intlMessages['settingsPage.menulist.subHeader.contactUs.title'])}
        </div>
        <ContactUsMenuItem
          label={upperFirst(intlMessages['settingsPage.menulist.menuitem.contactus.label'])}
        />
      </div>
      <div>
        <Flex justify="center" mx="25px" px="10px" py="20px">
          <Button size={"full"} onClick={debounceClick(logout)} isLoading={loggedOut}>
            <span>{upperFirst(intlMessages['settingsPage.menulist.menuitem.logout.label'])}</span>
          </Button>
        </Flex>
        
      </div>
    </StyledMenuList>
  )
}

export const MenuHeader = () => {
  const intlMessages = useSelector(getIntlMessages);
  const { user, isLoading: profileLoading } = useAuth();
  const firstName = user?.first_name;
  const lastName = user?.last_name;
  const email = user?.email;
  const avatar = user?.avatar;
  let [ name, setName ] = useState("");
  let [ initials, setInitials ] = useState("");

  useEffect(()=>{
    setName((firstName || lastName) ? formatName(intlMessages['format.fullName'], firstName, lastName) : "Beforedent User");
    setInitials( (name) ? name.charAt(0) : "B");
  }, [firstName, lastName] )

  if (profileLoading){
    return(
      <StyledMenuHeader>
        <h2>{intlMessages['settingsPage.menuheader.title']}</h2>
        <div className={cn('menuHeaderContent', 'loading')}>
          <LoadingEllipsis />
        </div>
      </StyledMenuHeader>
    )
  } else if (!profileLoading && email){
    return (
      <StyledMenuHeader>
        <h2>{startCase(intlMessages['settingsPage.menuheader.title'])}</h2>
        <div className={'menuHeaderContent'}>
          <div className={'image'}>
            <ProfileAvatar
              src={avatar}
              initials={initials}
            />
            <EditAvatar />
          </div>
          <div className={'details'}>
            <div className={'name'}>{name}</div>
            <div className={'email'}>{email}</div>
              <div className={'link'}>
                <Link to={'/settings/profile'}>
                  {startCase(intlMessages['settingsPage.menuheader.profile.url.label'])}{" >"}
                </Link>
              </div>
          </div>
        </div>
      </StyledMenuHeader>
    )
  } else {
    return (
      <StyledMenuHeader>
        <div>{''}</div>
      </StyledMenuHeader>
    )
  }
}

const MenuBreadCrumb = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const history = useHistory();

  const handleBack = () => {
    history.push('/settings')
  }

  if (isMobile){
    return(
      <Flex pb={['20px']}>
        <Button 
          variant="link"
          onClick={handleBack}
          leftIcon={<ArrowBackIcon color={'rgb(113, 128, 150)'} />}
        >
          <FormattedMessage 
            id={'settingsPage.navigation.goback'}
            defaultMessage={'Go back'}
          />
        </Button>
      </Flex>
    ) 
  } else {
    return <></>
  }

}

const MenuContent = () => {
  const intlMessages = useSelector(getIntlMessages);
  const params = useParams();

  if (!params.settingsId){
    return(
      <StyledMenuContent>
        <MenuBreadCrumb />
        {intlMessages['settingsPage.notfound']}
      </StyledMenuContent>
    )
  } else if (params.settingsId.toLowerCase() == "notifications"){
    return(
      <StyledMenuContent>
        <MenuBreadCrumb />
        <Notifications />
      </StyledMenuContent>
    )
  } else if (params.settingsId.toLowerCase() == "authentication"){
    return(
      <StyledMenuContent>
        <MenuBreadCrumb />
        <Authentication />
      </StyledMenuContent>
    )
  } else if (params.settingsId.toLowerCase() == "bulk_reports"){
    return(
      <StyledMenuContent>
        <MenuBreadCrumb />
        <DownloadReport />
      </StyledMenuContent>
    )
  } else if (params.settingsId.toLowerCase() == "profile"){
    return(
      <StyledMenuContent>
        <MenuBreadCrumb />
        <Profile />
      </StyledMenuContent>
    )
  } else if (params.settingsId.toLowerCase() == "howto"){
    return(
      <StyledMenuContent>
        <MenuBreadCrumb />
      </StyledMenuContent>
    )
  } else if (params.settingsId.toLowerCase() == "booking"){
    return(
      <StyledMenuContent>
        <MenuBreadCrumb />
        <Booking />
      </StyledMenuContent>
    )
  } else {
    return(
      <StyledMenuContent>
        <MenuBreadCrumb />
        {intlMessages['settingsPage.notfound']}
      </StyledMenuContent>
    )
  }
}

export const SettingsPage = () => {
  //const dispatch = useDispatch();
  const params = useParams();
  const intlMessages = useSelector(getIntlMessages);
  // Define the media query for mobile screens (max-width: 768px)
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  //const userUuid = useSelector(getUUID);

  //const profile = useSelector(getProfile);

  useEffect(()=>{
      /*ChannelService && ChannelService.shutdown();
      ChannelService.boot({
        "pluginKey": `${process.env.REACT_APP_CHANNELIO_KEY}`,
        "hideChannelButtonOnBoot": true,
        "memberId": userUuid ,
        "profile": {
          "name": `${profile.first_name} ${profile.last_name}`,
          "mobileNumber": "YOUR_USER_MOBILE_NUMBER",
          "email": profile.email ? profile.email : null
        },
      });
      return ()=>{ChannelService && ChannelService.shutdown();}*/
  }, [])


  // on mobile, if params?.settingsId is null then it should just show the menuColumn.
  // if it is not null, it should show the MenuContent page

  if (isMobile){
    return (
      <StyledSettingsPage>
        <div className={'title'}>{capitalize(intlMessages['settingsPage.title'])}</div>
        <div className={'wrapper'}>
          { (params?.settingsId == null) ? (
          <div className={'menuColumn'}>
            <div className={'menuHeader'}>
              <MenuHeader />
            </div>
            <div className={'menuList'}>
              <MenuList />
            </div>
          </div>
          ) : (
            <div className={'contentColumn'}>
              <MenuContent />
            </div>
          )}
        </div>
      </StyledSettingsPage>
    )
  } else {

    if (params?.settingsId == null){
      return <Redirect to={'/settings/profile'} />
    }

    return (
      <StyledSettingsPage>
        <div className={'title'}>{capitalize(intlMessages['settingsPage.title'])}</div>
        <div className={'wrapper'}>
          <div className={'menuColumn'}>
            <div className={'menuHeader'}>
              <MenuHeader />
            </div>
            <div className={'menuList'}>
              <MenuList />
            </div>
          </div>
          <div className={'contentColumn'}>
            <MenuContent />
          </div>
        </div>
      </StyledSettingsPage>
    )
  }


}
