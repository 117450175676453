import React from 'react';
import { 
    Box, Flex, HStack, VStack, Heading, Input, Select as ChakraSelect, Button, IconButton, Center,
    // eslint-disable-next-line no-unused-vars
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure,
    Table, Thead, Tbody, Tr, Th, Td  } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { FormattedMessage, useIntl } from 'react-intl';
import { useTreatmentCodeCollection as useCollectionQuery } from '../api/getTreatmentCodeCollection';


import { HeaderMenu } from '../components/HeaderMenu';
import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import PaginationNext from 'src/_images/icons/PaginationNext';
import { useSearchParamsObject } from 'src/_hooks/useSearchParams';
import { useHistory } from 'react-router';
import { CaretUpIcon } from 'src/_images/icons/CaretUpIcon';
import { CaretDownIcon } from 'src/_images/icons/CaretDownIcon';
import { EditTreatmentCodeForm, TreatmentCodeForm } from '../components/TreatmentCodeForm';

const COLLECTION_PAGE_KEY = "treatmentCodes";


export const SearchSection = ({searchInput, setSearchInput, handleSearch}) => {
    const { formatMessage } = useIntl();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    return (
        <Box>     
            <Flex mt={'30px'}>
                <Input type="text" 
                    placeholder={formatMessage({id: "patientsListPage.patientSearch.button.search", defaultMessage: "Search"})}
                    value={searchInput} 
                    onKeyUp={handleKeyPress} 
                    onChange={(e) => setSearchInput(e.target.value)} 
                />
                <Button 
                    variant={"outlined"}
                    onClick={handleSearch} 
                >
                    <FormattedMessage 
                        id="patientsListPage.patientSearch.button.search"
                        defaultMessage="Search"
                    />
                </Button>
            </Flex>
        </Box>
    )
} 


const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, isLoading}) => {
    return (
        <HStack spacing={3} >
            <IconButton 
                icon={<PaginationPrevious />} 
                isDisabled={!canPreviousPage}
                isLoading={isLoading}
                onClick={previousPage}
                borderRadius={'5px'}
            />
            <ChakraSelect 
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) : 0
                    setPageIndex(page)
                }}
                isDisabled={total < 2}
            >
                {Array.from({length: total}, (_, i) => (
                    <option key={i} value={i} selected={pageIndex == i}>
                        {i + 1}
                    </option>
                ))}
            </ChakraSelect>
            <IconButton 
                icon={<PaginationNext />} 
                isDisabled={!canNextPage}
                isLoading={isLoading}
                borderRadius={'5px'}
                onClick={nextPage}
            />
        </HStack>
    
    )
}

const PageTable = ({data, searchParams, isLoading, pagination, setPagination, onEditRow}) => {
    const history = useHistory();


    const handleSort = (key) => {
        let params = searchParams;
        if (params.sort_by === key && params.sort_direction === 'desc') {
          // update API query parameters here for descending sort
          //setSortConfig({ key, direction: 'descending' });
          if (params.sort_direction === 'asc'){
            params.sort_direction = 'desc'; 
          } else {
            params.sort_direction = 'asc'; 
          }
          const newParams = new URLSearchParams(params)
          history.replace({search: newParams.toString()})
        } else {
          //setSortConfig({ key, direction: 'ascending' });
          params.sort_by = key;
          params.sort_direction = 'desc';
          const newParams = new URLSearchParams(params)
          history.replace({search: newParams.toString()})
        }
    };
    
    const getSortIcon = (key) => {
      let isAsc = false;
      let isDesc = false;
      if (searchParams.sort_by == key){
        isAsc = searchParams.sort_direction === 'asc';
        isDesc = searchParams.sort_direction === 'desc';
      };
      // #A0B1BA
      // #EFEFEF
      return (
        <VStack spacing={'3px'} ml={'8px'}>
          <CaretUpIcon color={isAsc ? '#405159' : '#C7D3D9' } width={14} />
          <CaretDownIcon color={isDesc ? '#405159' : '#C7D3D9' }  width={14}/>
        </VStack>
      )
    };
    
    const columns = React.useMemo(
        () => [
            {
                header: 'Country',
                id: 'country',
                accessorKey: 'country',
                sortable: false,
            },
            {
                header: 'Code',
                id: 'code',
                accessorKey: 'code',
                sortable: true,
            },
            {
                header: 'Code Origin',
                id: 'code_origin',
                accessorKey: 'code_origin',
                sortable: false,
            },
            {
                header: 'Treatment Class',
                id: 'treatment_type',
                accessorKey: 'treatment_type',
                sortable: false,
            },
            {
                header: 'Name',
                id: 'name',
                accessorKey: 'name',
                sortable: false,
            },
            {
                header: 'Description',
                id: 'description',
                accessorKey: 'description',
                sortable: false,
            }
        ],
        []
    )

    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data?.treatment_codes || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })


    const handleRowClick = (row) => {
        onEditRow(row?.id)
    }

    return (
        <>
            <Table mt={'20px'}>
                <Thead>
                    {(table.getHeaderGroups() || []).map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    <Flex display={'flex'} align={'center'}>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        <Box
                                            _hover={{cursor: header.column.columnDef.sortable ? 'pointer' : 'default'}}
                                            onClick={() => header.column.columnDef.sortable && handleSort(header.id)}
                                        >
                                            {header.column.columnDef.sortable && getSortIcon(header.id)}
                                        </Box>
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                        <Tr key={row.id} onClick={() => handleRowClick(row.original)}  _hover={{cursor: 'pointer', bg: '#F7F7F7'}}>
                            {row.getVisibleCells().map(cell => {
                            return (
                                <Td key={cell.id} color={row.original.is_active ? '#212529' : '#6B8A99'} >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Td>
                            )
                            })}
                        </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <Flex w="full" mt={'50px'} justify={'flex-end'}>
                <Pagination 
                    isLoading={isLoading}
                    canPreviousPage={table.getCanPreviousPage()}
                    canNextPage={table.getCanNextPage()}
                    pageIndex={table.getState().pagination.pageIndex}
                    total={table.getPageCount()}
                    nextPage={table.nextPage}
                    previousPage={table.previousPage}
                    setPageIndex={table.setPageIndex}
                />
            </Flex>
        </>
    )
}

const EditFormModal = ({
    isOpen,
    onClose,
    onSuccess,
    id
}) => {
    // email, password, first_name, last_name, date_of_birth
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent minW="800px" bg="#f7f9fa">
                <ModalHeader>
                    <Center>
                        <FormattedMessage

                            id={'adminPage.treatmentCodeFormModal.title'}
                            defaultMessage={'Edit Treatment Code and Fees'}
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Heading as={'h2'}>
                        <FormattedMessage
                            id={'adminPage.treatmentCodeFormModal.title'}
                            defaultMessage={'Treatment Code Details'}
                        />
                    </Heading>
                    <Box>
                        <EditTreatmentCodeForm
                            onSuccess={onSuccess}
                            onClose={onClose}
                            id={id}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>

    )
}

const NewFormModal = ({
    isOpen,
    onClose,
    onSuccess
}) => {
    // email, password, first_name, last_name, date_of_birth, gender, user_type, country, categories, description, 
    // clini_id, account_role... 
    // clinic_name, branch, address, timezone, geopoint

    const handleOnSuccess = () => {
        onSuccess();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
          <ModalOverlay bg="blackAlpha.300" />
          <ModalContent minW="800px" bg="#f7f9fa">
            <ModalHeader>
              <Center>
                    <FormattedMessage 
                        id={'adminPage.treatmentCodeFormModal.title'}
                        defaultMessage={'Create Treatment Code and Fees'}
                    />
              </Center>
            </ModalHeader>
            <ModalBody>
                <Heading as={'h2'}>
                    <FormattedMessage 
                        id={'adminPage.treatmentCodeFormModal.title'}
                        defaultMessage={'Treatment Code Details'}
                    />
                </Heading>
              <Box>
                <TreatmentCodeForm
                  onSuccess={handleOnSuccess}
                  onClose={onClose}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
    )
}

const PageHeader = ({onNewItemAdded}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <NewFormModal isOpen={isOpen} onClose={onClose} onSuccess={onNewItemAdded} />
            <Flex justify={'space-between'} align={'end'}>
                <HeaderMenu page={COLLECTION_PAGE_KEY} />
                <Flex w={'full'} justify={'flex-end'}>
                    <Button variant="outline" onClick={onOpen}>
                        <FormattedMessage
                            id="admin.treatmentCodePage.button.newRequest"
                            defaultMessage="+ Create Treatment Code"
                        />
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}

const CollectionPage = () => {
    const searchParams = useSearchParamsObject();
    const history = useHistory();
    const PAGE_SIZE = 15;
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    })
    const {data, isLoading, isFetching,refetch} = useCollectionQuery({
        ...searchParams, offset: pagination.pageIndex, pg_sz: pagination.pageSize
    })

    console.log("Treatment collection code ")

    const handleOpenEditModal = (id) => {
        const params = searchParams;
        params.editItem = id;
        const newParams = new URLSearchParams(params);
        history.push({search: newParams.toString()})
    } 

    const handleCloseEditModal = () => {
        const params = searchParams;
        delete params.editItem;
        const newParams = new URLSearchParams(params);
        history.replace({search: newParams.toString()})
    }

    const handleEditSuccess = () => {
        const params = searchParams;
        delete params.editItem;
        const newParams = new URLSearchParams(params);
        refetch()
        history.replace({search: newParams.toString()})
    }

    return (
        <Box
            minH="100vh"
            pt="102px"
            ml="90px"
            pr="20px"
            bg="#fff"
            pl="60px"
            pb={"100px"}
        >
            <EditFormModal 
                id={searchParams?.editItem} 
                isOpen={!!(searchParams?.editItem)} 
                onClose={handleCloseEditModal} 
                onSuccess={handleEditSuccess} 
            />
            <PageHeader onNewItemAdded={refetch}/>
            <PageTable data={data} onEditRow={handleOpenEditModal} searchParams={searchParams} isLoading={isLoading || isFetching} pagination={pagination} setPagination={setPagination} />
        </Box>
    )
}

export { CollectionPage as TreatmentCodeCollectionPage }