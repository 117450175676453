import React from 'react';
import { PrivateRouteWithChildren } from 'src/_components/PrivateRoute';
import { TreatmentPlanPage } from './TreatmentPlanPage';
import { TreatmentPlanCollectionPage } from './TreatmentPlanCollectionPage';

export const Routes = () => {
    return (
        <>
            <PrivateRouteWithChildren path="/treatmentPlans/:treatment_id">
                <TreatmentPlanPage />
            </PrivateRouteWithChildren>
            <PrivateRouteWithChildren exact path="/treatmentPlans">
                <TreatmentPlanCollectionPage />
            </PrivateRouteWithChildren>
        </>
    )
}