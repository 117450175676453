import React from 'react';
import { 
    SimpleGrid, Grid, GridItem, Text, Box, Th, Tr, Td, Button, Flex,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Heading, Center 
 } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PlusIcon } from 'src/_images/icons/PlusIcon';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { Form , Field } from 'react-final-form';

import {required, isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { nanoid } from 'nanoid';
import { cloneDeep } from 'lodash';
import { deleteFeeSchedule } from '../api/services';
import { useSelectOptions } from '../api/getSelectOptions';

const GRID_TEMPLATE_COLUMNS = ["2fr 3fr 3fr 3fr 2fr 3fr"];
const GRID_GAP = '15px';
const GRID_PROPS = {
    px: '10px',
    borderBottom: '1px solid #E2E8F0',
    py:'10px'
}

const DiscountPercentage = ({base_price, average_price}) => {
    const diff = average_price - base_price;
    const percentage = (diff / average_price) * 100;
    let color = {};
    if (percentage > 40){
        color = {color: 'green.400'}
    } else if (percentage > 20){
        color = {color: 'orange.400'}
    }

    if (isNaN(percentage) || !isFinite(percentage)){
        return <Text>-</Text>;
    }


    
    return (
        <Text {...color}>
            {percentage.toFixed(0)}%
        </Text>
    )
}

const FeeScheduleForm = ({onClose, onSaved, currency, currencySymbol, country, initValues={} }) => {
    const colSpacing = 4;
    const rowSpacing = 4;
    const { formatMessage } = useIntl();
    const { data: planData, isLoading: planIsLoading, isFetching: planIsFetching } = useSelectOptions({option: 'plan_types', country});
    const defaultData = React.useMemo(() => [], []);


    const handleSubmit = (values) => {
        const newValues = cloneDeep(values);
        if (!newValues?.id){
            newValues.id = 'TEMP_'+nanoid();
        }
        onSaved(newValues)
    }

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                initialValues={{currency, ...initValues }}
                mutators={{ 
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                    }
                }}
                render = {
                    ({
                        handleSubmit,
                        submitting,
                        pristine,
                        values
                    }) => {
                        return (
                            <Box as={'form'} onSubmit={handleSubmit}>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="plan" validate={required} isDisabled={true}>
                                        {({input, meta}) => (
                                            <SelectOptionField 
                                                isDisabled={!country}
                                                isLoading={planIsLoading || planIsFetching}
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code_fee.plan_level.label', defaultMessage: 'Plan Level'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code_fee.plan_level.placeholder', defaultMessage: 'Plan Level'})}
                                                input={input}
                                                selectOptions={planData?.options || defaultData}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>

                                    <Field name="currency" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                isDisabled={true}
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code_fee.currency.label', defaultMessage: 'Currency'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code_fee.currency.placeholder', defaultMessage: 'Currency'})}
                                                {...input}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                inputLeftElement={currencySymbol}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="base_price" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code_fee.base_price.label', defaultMessage: 'Our Price'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code_fee.base_price.placeholder', defaultMessage: 'Our Price'})}
                                                {...input}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                inputLeftElement={currencySymbol}
                                            />
                                        )}
                                    </Field>
                                    <Field name="average_price">
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code_fee.average_price.label', defaultMessage: 'Comparable Price'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code_fee.average_price.placeholder', defaultMessage: 'Comparable Price'})}
                                                {...input}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                inputLeftElement={currencySymbol}
                                                inputRightElement={<DiscountPercentage base_price={values?.base_price} average_price={input.value} />}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                    <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                        <FormattedMessage 
                                            id="adminPage.form.cancel.button.label"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button type="submit" isLoading={submitting} isDisabled={submitting || pristine}>
                                        <FormattedMessage 
                                            id="adminPage.form.submit.button.label"
                                            defaultMessage="Submit"
                                        />
                                    </Button>
                                </SimpleGrid>
                            </Box>
                        )
                    }
                }
            />
        </>)
}

const ConfirmDeleteModal = ({treatmentCode, treatmentName, isOpen, onClose, onConfirm}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent minW="350px" bg="#f7f9fa">
                <ModalHeader>
                    <Center>
                        <FormattedMessage

                            id={'adminPage.treatmentCodeFeeFormModal.feeSchedule.confirmDelete.title'}
                            defaultMessage={'Delete Fee Schedule'}
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Heading as={'h2'}>
                        {`${treatmentCode}: ${treatmentName}`}
                    </Heading>
                    <Text mt={'15px'}>
                        <FormattedMessage

                            id={'adminPage.treatmentCodeFeeFormModal.feeSchedule.confirmDelete.message'}
                            defaultMessage={'Are you sure you want to delete this fee schedule?'}
                        />
                    </Text>
                    <SimpleGrid columns={[2]} spacing={4} mt={'20px'}>
                        <Button variant={'outline'} onClick={onClose}>
                            <FormattedMessage
                                id="adminPage.form.cancel.button.label"
                                defaultMessage="Cancel"
                            />
                        </Button>
                        <Button variant={'delete'} onClick={onConfirm}>
                            <FormattedMessage
                                id="adminPage.form.delete.button.label"
                                defaultMessage="Delete"
                            />
                        </Button>
                    </SimpleGrid>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

const FeeScheduleItem = ({item, country, currency, currencySymbol, treatmentCode, treatmentName, handleEdit, handleDelete}) => {
    const [ deleteId, setIsDeleteId ] = React.useState(null);
    const [ editId, setIsEditId ] = React.useState(null);

    const handleSubmit = (value) => {
        handleEdit({
            ...item,
            ...value
        });
        setIsEditId(null);
    }

    const handleDeleteItem = (id) => {
        handleDelete(id);
        setIsDeleteId(null);
    }


    return (
        <Box key={item?.id}>
            <Grid {...GRID_PROPS} templateColumns={GRID_TEMPLATE_COLUMNS} gap={GRID_GAP} mt={'10px'}>
                <GridItem>
                    <Text>
                        {item?.plan?.name || "-"}
                    </Text>
                </GridItem>
                <GridItem>
                    <Text>
                        {item?.treatment_class || "-"}
                    </Text>
                </GridItem>
                <GridItem>
                    <Text>
                        {item?.base_price}
                    </Text>
                </GridItem>
                <GridItem>
                    <Text>
                        {item?.average_price}
                    </Text>
                </GridItem>
                <GridItem>
                    <DiscountPercentage 
                        base_price={item?.base_price}
                        average_price={item?.average_price}
                    />
                </GridItem>
                <GridItem>
                    <ConfirmDeleteModal 
                        treatmentCode={treatmentCode}
                        treatmentName={treatmentName}
                        isOpen={deleteId} 
                        onClose={() => setIsDeleteId(null)} 
                        onConfirm={() => handleDeleteItem(item.id)} 
                    />
                    <FeeScheduleItemFormModal
                        isOpen={editId}
                        treatmentCode={treatmentCode}
                        treatmentName={treatmentName}
                        initValues={item}
                        currency={currency}
                        country={country}
                        currencySymbol={currencySymbol}
                        onSuccess={handleSubmit}
                        onClose={() => setIsEditId(null)}
                        onCancel={() => setIsEditId(null)}
                        //onSuccess={handleAddItem} 
                    />
                    <Flex>
                        <Button size={'sm'} variant={'outline'} onClick={() => setIsEditId(item.id)}>
                            <FormattedMessage 
                                id={'adminPage.reportTemplateFormModal.locale.edit.delete'}
                                defaultMessage="Edit"
                            />
                        </Button>
                        <Button ml={'4px'} size={'sm'} variant={'deleteOutline'} onClick={() => setIsDeleteId(item.id)}>
                            <FormattedMessage 
                                id={'adminPage.reportTemplateFormModal.locale.button.delete'}
                                defaultMessage="Delete"
                            />
                        </Button>
                    </Flex>
                </GridItem>
            </Grid>
        </Box>
    )
}

const FeeScheduleItemFormModal = ({
    isOpen,
    onClose,
    onSuccess,
    id,
    country,
    currency,
    currencySymbol,
    treatmentCode,
    treatmentName,
    initValues={}
}) => {
    // email, password, first_name, last_name, date_of_birth
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent minW="500px" bg="#f7f9fa">
                <ModalHeader>
                    <Center>
                        <FormattedMessage
                            id={'adminPage.treatmentCodeFeeFormModal.title'}
                            defaultMessage={'Create Fee Schedule'}
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Heading as={'h2'}>
                        {`${treatmentCode}: ${treatmentName}`}
                        {/*<FormattedMessage
                            id={'adminPage.treatmentCodeFeeForm.title'}
                            defaultMessage={'Fee Schedule Details'}
                        />*/}
                    </Heading>
                    <Text>
                    </Text>
                    <Box>
                        <FeeScheduleForm
                            country={country}
                            currency={currency}
                            currencySymbol={currencySymbol}
                            onSaved={onSuccess}
                            onClose={onClose}
                            id={id}
                            initValues={initValues}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>

    )
}

export const FeeScheduleItemList = ({
    inputValue, onChange, country, treatmentCode, treatmentName
}) => {
    const [ isAdding, setIsAdding ] = React.useState(false);
    // input value should be [{locale: 'th', title: 'title' , message: '{{teeth}} are god.'}, ... ]

    const handleAddItem = (newData) => {
        console.log(newData)
        onChange(
            [...inputValue, newData]
        )
        setIsAdding(false);
    }

    const handleDeleteItem = async (id) => {
        if (!id || (typeof id === "string" && id.startsWith("TEMP_"))) {
            onChange(
                inputValue
                    .filter(item => !(item.id === id)) // Remove the item 
            )
        } else {
            // actually delete the item since it is already saved in the backend
            try{
                await deleteFeeSchedule(id);
                onChange(
                    inputValue
                        .filter(item => !(item.id === id)) // Remove the item 
                )
            } catch (e){
                console.error("Error deleting fee schedule ", e)
            }
        }
    }

    const handleUpdateItem = (newData) => {
        onChange(
            inputValue.map((item) => {
                if (item.id === newData?.id) {
                    return {
                        ...item,
                        ...newData
                    }
                }
                return item;
            }
        ))
    }

    return (
        <Box mt={'10px'}>
            <FeeScheduleItemFormModal 
                country={country?.code}
                currency={country?.currency}
                currencySymbol={country?.currency_symbol}
                treatmentCode={treatmentCode}
                treatmentName={treatmentName}
                isOpen={isAdding } 
                onClose={() => setIsAdding(false)} 
                onSuccess={handleAddItem} 
                onCancel={() => setIsAdding(false)}
            />
            <Grid {...GRID_PROPS} templateColumns={GRID_TEMPLATE_COLUMNS} gap={GRID_GAP} >
                <GridItem>
                    <Th fontWeight={'bold'}>
                        <FormattedMessage 
                            id={'adminPage.treatmentCodeFeeFormModal.treatment_code_fee.tableHeader.plan_level'}
                            defaultMessage="Plan Level"
                        />
                    </Th>

                </GridItem>
                <GridItem>
                    <Th>
                        <FormattedMessage 
                            id={'adminPage.treatmentCodeFeeFormModal.treatment_code_fee.tableHeader.treatment_class'}
                            defaultMessage="Treatment Class"
                        />
                    </Th>
                </GridItem>
                <GridItem>
                    <Th>
                        <FormattedMessage 
                            id={'adminPage.treatmentCodeFeeFormModal.treatment_code_fee.tableHeader.base_price'}
                            defaultMessage="Our Price"
                        />
                    </Th>
                </GridItem>
                <GridItem>
                    <Th>
                        <FormattedMessage 
                            id={'adminPage.treatmentCodeFeeFormModal.treatment_code_fee.tableHeader.average_price'}
                            defaultMessage="Comparable Price"
                        />
                    </Th>
                </GridItem>
                <GridItem>
                    <Th>
                        <FormattedMessage 
                            id={'adminPage.treatmentCodeFeeFormModal.treatment_code_fee.tableHeader.discount'}
                            defaultMessage="Discount"
                        />
                    </Th>
                </GridItem>
                <GridItem>
                    <Th></Th>
                </GridItem>
            </Grid>
            {(inputValue || []).map((item) => (
                <FeeScheduleItem
                    key={item.id}
                    item={item}
                    treatmentCode={treatmentCode}
                    treatmentName={treatmentName}
                    country={country?.code}
                    currency={country?.currency}
                    currencySymbol={country?.currency_symbol}
                    handleEdit={handleUpdateItem}
                    handleDelete={handleDeleteItem}
                />
            ))}
            <Box mt={'10px'} />
            <>
                <Tr>
                    <Td>
                        <Button

                            isDisabled={!country?.currency || isAdding}
                            onClick={() => setIsAdding(true)}
                            leftIcon={<PlusIcon height={8} width={8} />}
                        >
                            <FormattedMessage 
                                id={'adminPage.treatmentCodeFeeFormModal.treatment_code_fee.button.addItem'}
                                defaultMessage="Add Fee Schedule" 
                            />
                        </Button></Td>
                    <Td></Td>
                    <Td></Td>
                </Tr>
            </>
        </Box>
    )
}