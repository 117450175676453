import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import {
    Box, Flex, SimpleGrid, Button, Spinner,
    FormControl, FormLabel, FormErrorMessage//, VStack, Input
} from '@chakra-ui/react';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_libs/forms';
import { useSelectOptions } from '../api/getSelectOptions';
import { convertToAPIValues } from 'src/_libs/forms';

import { createTreatmentCode as createService, editTreatmentCode } from '../api/services';
import { useTreatmentCodeDetails, updateTreatmentCodeDetails } from '../api/getTreatmentCodeDetails';

import { FeeScheduleItemList } from './FeeScheduleForm';

import { isFinalFormMetaInvalid, getFinalFormMetaError } from 'src/_libs/forms';
import { ErrorNotFound } from 'src/_components/NoMatch/ErrorNotFound';
import { CheckboxComponent } from 'src/_components/Forms/CheckBoxComponent';

const TreatmentCodeFeeSchedule = ({
    input, meta, country, treatmentCode, treatmentName, isEdit=false
}) => {
    // fees = [{... treatmetnFeeSchedule}]
    return (
        <FormControl id={'fees'} >
            <FormLabel>
                <FormattedMessage
                    id="adminPage.form.field.treatment_code.fees.label"
                    defaultMessage="Fee Schedule"
                />
            </FormLabel>
        
            <Box columns={[1]} spacing={4}>
                <FeeScheduleItemList
                    isEdit={isEdit}
                    country={country}
                    inputValue={input?.value}
                    onChange={input?.onChange}
                    meta={meta}
                    treatmentCode={treatmentCode}
                    treatmentName={treatmentName}
                />
            </Box>
            <FormErrorMessage>{meta?.error ? meta?.error  : ""}</FormErrorMessage>
        </FormControl>
    )
}

const processCountry = (country, countryList=[]) => {
    // Example: Replace this logic with your actual function
    console.log("country ", country)
    console.log("countryList ", countryList)
    return countryList.find(obj => (obj.value == country || obj.code == country));
};

const processObject = (obj, countryList) => {
    const updatedObj = {};

    
    // Iterate through each key-value pair
    Object.entries(obj).forEach(([key, value]) => {
        if (key === "country") {
            updatedObj[key] = processCountry(value, countryList); // Run your function on the country value
        } else {
            updatedObj[key] = value; // Keep other keys as-is
        }
    });

    return updatedObj;
};

// Edit user is much different from create so new form is made
export const EditTreatmentCodeForm = ({
    onSuccess,
    onClose,
    id
}) => {
    const { data, isLoading, isFetching, isError, error } = useTreatmentCodeDetails({id})
    const { data: countriesData, isLoading: countriesIsLoading, isFetching: countriesIsFetching } = useSelectOptions({option: 'countries'});
    const { formatMessage } = useIntl();
    const defaultData = React.useMemo(() => [], []);
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const { fees , ...rest } = valuesCopy;
        const convertedValues = convertToAPIValues(rest);
        //convertedValues.subtype = snakeCase(trim(convertedValues.subtype));
        convertedValues.fees = fees.map(fee => {
                    // eslint-disable-next-line no-unused-vars
                    return convertToAPIValues(fee);
            }
        );

        try{
            const result = await editTreatmentCode(id, convertedValues);
            console.log("result ", result)
            updateTreatmentCodeDetails(result?.id, result)
            onSuccess && onSuccess(result);
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    if (isError){
        return (
            <Box>
                <ErrorNotFound error={error} size={"md"} buttonType={'refresh'} />
            </Box>
        )
    }

    if (isLoading || isFetching){
        return ( 
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        )
    } else {
        
        return (
            <>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={processObject(data, countriesData?.options || defaultData)}
                    mutators={{ 
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    render = {
                        ({
                            handleSubmit,
                            submitting,
                            pristine,
                            values
                        }) => {
                            return (
                                <Box as={'form'} onSubmit={handleSubmit}>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="is_active">
                                        {({input, meta}) => (
                                            <CheckboxComponent
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.is_active.label', defaultMessage: 'Is Active?'})}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="country" validate={required}>
                                        {({input, meta}) => (
                                            <SelectOptionField 
                                                isLoading={countriesIsFetching || countriesIsLoading}
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.countries.label', defaultMessage: 'Country'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.countries.label', defaultMessage: 'Country'})}
                                                input={input}
                                                selectOptions={countriesData?.options || defaultData}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                    <Field name="code_origin">
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.code_origin.label', defaultMessage: 'Code Origin'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.code_origin.placeholder', defaultMessage: 'Code Origin'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="code" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.code.label', defaultMessage: 'Code'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.code.placeholder', defaultMessage: 'Code'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                    <Field name="treatment_type">
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.treatment_type.label', defaultMessage: 'Treatment Category'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.treatment_type.placeholder', defaultMessage: 'Treatment Category'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="name" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.name.label', defaultMessage: 'Title'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.name.placeholder', defaultMessage: 'Title'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="description" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                type='textarea'
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.description.label', defaultMessage: 'Description'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.description.placeholder', defaultMessage: "Description"})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="fees">
                                        {({input, meta}) => (
                                            <TreatmentCodeFeeSchedule
                                                input={input}
                                                meta={meta}
                                                country={values?.country}
                                                treatmentCode={values?.code}
                                                treatmentName={values?.name}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                    <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                        <FormattedMessage 
                                            id="adminPage.form.cancel.button.label"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button type="submit" isLoading={submitting} isDisabled={submitting || pristine}>
                                        <FormattedMessage 
                                            id="adminPage.form.submit.button.label"
                                            defaultMessage="Submit"
                                        />
                                    </Button>
                                </SimpleGrid>
                                </Box>
                            )
                        }
                    }
                />
            </>
        )
    }   
}

export const TreatmentCodeForm = ({
    onSuccess,
    onClose,
    initValues={ 'fees': []}
}) => {

    const { data: countriesData, isLoading: countriesIsLoading, isFetching: countriesIsFetching } = useSelectOptions({option: 'countries'});
    const { formatMessage } = useIntl();
    const defaultData = React.useMemo(() => [], []);
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const { fees , ...rest } = valuesCopy;
        const convertedValues = convertToAPIValues(rest);
        //convertedValues.subtype = snakeCase(trim(convertedValues.subtype));
        convertedValues.fees = fees.map(fee => {
                    // eslint-disable-next-line no-unused-vars
                    const { id, ...rest } = fee;
                    return convertToAPIValues(rest);
            }
        );
        try{
            const result = await createService(convertedValues);
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                initialValues={initValues}
                mutators={{ 
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                    }
                }}
                render = {
                    ({
                        handleSubmit,
                        submitting,
                        pristine,
                        values
                    }) => {
                        return (
                            <Box as={'form'} onSubmit={handleSubmit}>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="country" validate={required}>
                                        {({input, meta}) => (
                                            <SelectOptionField 
                                                isLoading={countriesIsFetching || countriesIsLoading}
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.countries.label', defaultMessage: 'Country'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.countries.label', defaultMessage: 'Country'})}
                                                input={input}
                                                selectOptions={countriesData?.options || defaultData}
                                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                    <Field name="code_origin">
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.code_origin.label', defaultMessage: 'Code Origin'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.code_origin.placeholder', defaultMessage: 'Code Origin'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="code" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.code.label', defaultMessage: 'Code'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.code.placeholder', defaultMessage: 'Code'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                    <Field name="treatment_type">
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.treatment_type.label', defaultMessage: 'Treatment Category'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.treatment_type.placeholder', defaultMessage: 'Treatment Category'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="name" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.name.label', defaultMessage: 'Title'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.name.placeholder', defaultMessage: 'Title'})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="description" validate={required}>
                                        {({input, meta}) => (
                                            <InputComponent 
                                                type='textarea'
                                                label={formatMessage({id: 'adminPage.form.field.treatment_code.description.label', defaultMessage: 'Description'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.treatment_code.description.placeholder', defaultMessage: "Description"})}
                                                {...input}
                                               isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                                error={getFinalFormMetaError(meta)}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="fees">
                                        {({input, meta}) => (
                                            <TreatmentCodeFeeSchedule
                                                input={input}
                                                meta={meta}
                                                country = {values?.country}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                    <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                        <FormattedMessage 
                                            id="adminPage.form.cancel.button.label"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button type="submit" isLoading={submitting} isDisabled={submitting || pristine}>
                                        <FormattedMessage 
                                            id="adminPage.form.submit.button.label"
                                            defaultMessage="Submit"
                                        />
                                    </Button>
                                </SimpleGrid>
                            </Box>
                        )
                    }
                }
            />
        </>
    ) 
}