import { useMutation } from 'react-query';
import { queryClient } from 'src/_libs/react-query';

import { toString } from 'lodash';

import { updateTreatmentPlan, cancelTreatmentPlan, rejectTreatmentPlan, completeTreatmentPlan, archiveTreatmentPlan, startTreatmentPlan } from './services';
import { QUERY_KEY_NAMES } from './constants';

const onStatusUpdateSuccess = async (id, statusData) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_TREATMENTPLAN_DETAILS, {id: toString(id)}], (oldData) => {
        const newData =  {
            treatment_plan: {
                ...(oldData?.treatment_plan || {}),
                ...statusData
            }
        }
        return newData
    });     
    queryClient.invalidateQueries({queryKey: [QUERY_KEY_NAMES.GET_TREATMENTPLAN_COLLECTION]});  

}     


export const useStartTreatmentPlan = ({ id, clinic}) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.UPDATE_TREATMENTPLAN_STATUS_INPROGRESS,
        onSuccess: () => onStatusUpdateSuccess(id, {status: 'in_progress'}),
        mutationFn: (data) => {
            return startTreatmentPlan(id, data, clinic);
        },
    });
}

export const useArchiveTreatmentPlan = ({ id, clinic}) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.UPDATE_TREATMENTPLAN_STATUS_ARCHIVE,
        onSuccess: () => onStatusUpdateSuccess(id, {archived: true}),
        mutationFn: (data) => {
            return archiveTreatmentPlan(id, data, clinic);
        },
    });
}

export const useCompleteTreatmentPlan = ({ id, clinic}) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.UPDATE_TREATMENTPLAN_STATUS_COMPLETE,
        onSuccess: () => onStatusUpdateSuccess(id, {status: 'completed'}),
        mutationFn: (data) => {
            return completeTreatmentPlan(id, data, clinic);
        },
    });
}

export const useRejectTreatmentPlan = ({ id, clinic}) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.UPDATE_TREATMENTPLAN_STATUS_REJECT,
        onSuccess: () => onStatusUpdateSuccess(id, {status: 'rejected'}),
        mutationFn: (data) => {
            return rejectTreatmentPlan(id, data, clinic);
        },
    });
}


export const useCancelTreatmentPlan = ({ id, clinic}) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.UPDATE_TREATMENTPLAN_STATUS_CANCEL,
        onSuccess: () => onStatusUpdateSuccess(id, {status: 'canceled'}),
        mutationFn: (data) => {
            return cancelTreatmentPlan(id, data, clinic);
        },
    });
}

const createQuery = async ({ id, data, clinic }) => {
    return updateTreatmentPlan(id, data, clinic);
}

const onSuccess = async (data) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_TREATMENTPLAN_DETAILS, {id: toString(data?.unique_id)}], (oldData) => {
        const newData =  {
            treatment_plan: {
                ...(oldData?.treatment_plan || {}),
                ...data
            }
        }
        return newData
    });
}     

export const useUpdateTreatmentPlan = ({ id, clinic }) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.UPDATE_TREATMENTPLAN,
        onSuccess: (data) => onSuccess(data),
        mutationFn: (data) => {        
            return createQuery({ id, data, clinic });
        },
    });
}