import React from 'react';
import { PrivateRouteWithChildren } from 'src/_components/PrivateRoute';
import { UserCollectionPage } from './UserCollectionPage';
import { ClinicCollectionPage } from './ClinicCollectionPage';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/_libs/auth';
import { UserRequestCollectionPage } from './UserRequestCollectionPage';
import { PatientUserCollectionPage } from './PatientUserCollectionPage';
import { ReportTemplateCollectionPage } from './ReportTemplateCollectionPage';
import { TreatmentCodeCollectionPage } from './TreatmentCodeCollectionPage';


export const Routes = () => {
    const {user} = useAuth();

    const BASE_ADMIN_PATH = "/adm/collections";

    if (user?.is_staff != true) {
        return (
            <>
                <Redirect to={"/"} />
            </>
        );
    }
    return (
        <>
        <PrivateRouteWithChildren path={`${BASE_ADMIN_PATH}/treatmentCodes`}>
            <TreatmentCodeCollectionPage />
        </PrivateRouteWithChildren>
            <PrivateRouteWithChildren path={`${BASE_ADMIN_PATH}/reportTemplates`}>
                <ReportTemplateCollectionPage />
            </PrivateRouteWithChildren>
            <PrivateRouteWithChildren path={`${BASE_ADMIN_PATH}/dentists`}>
                <UserCollectionPage />
            </PrivateRouteWithChildren>
            <PrivateRouteWithChildren path={`${BASE_ADMIN_PATH}/userRequests`} >
                <UserRequestCollectionPage />
            </PrivateRouteWithChildren> 
            <PrivateRouteWithChildren path={`${BASE_ADMIN_PATH}/patientUsers`}>
                <PatientUserCollectionPage />
            </PrivateRouteWithChildren>
            <PrivateRouteWithChildren path={`${BASE_ADMIN_PATH}/clinics`}>
                <ClinicCollectionPage />
            </PrivateRouteWithChildren>
            <PrivateRouteWithChildren exact path={`${BASE_ADMIN_PATH}`}>
                <Redirect to={'/adm/collections/clinics'} />
            </PrivateRouteWithChildren>
        </>
    );
};
