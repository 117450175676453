import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchReportTemplateCollection as fetchRequest } from './services';

export const QUERY_KEY_NAME = 'Admin_ReportTemplateCollection';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (queryParams) => {
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    const searchParams = new URLSearchParams(paramsObj)
    return fetchRequest(searchParams)
}

export const useReportTemplateCollection = ({ report_type="", category="", sort_by="category", sort_direction="asc", offset=0, pg_sz=10 }) => {
    return useQuery({
        ...newQueryConfig,
        queryKey: [QUERY_KEY_NAME, {report_type, category, sort_by, sort_direction, offset, pg_sz}],
        queryFn: () => queryRequest({report_type, category, sort_by, sort_direction, offset, pg_sz}), // in brackets because im parsing the object in queryRequest
      });
}