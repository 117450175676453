/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Nav, Navbar } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { createSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { URLS } from '../../_config';
import { findKey } from 'lodash';

import { Text, Flex, Menu, Button, MenuButton, MenuList, MenuItem, Image } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl";

import logo from 'src/_images/logoPDHPrimary.png'

import { actions as alertActions } from '../../AlertDialog/_redux/actions';
import { actions as chatActions } from '../../ChatPage/_redux/actions';
import { actions } from '../../App/_redux/actions';

// selectors
import { getIntlMessages} from '../../App/_redux/selectors';
import { consoleLog } from "../../_helpers/consoleLog";
import { useAuth } from "src/_libs/auth";
import { useClinicRoles } from "src/App/api/getClinicRoles";
import { SelectChevronDown } from "src/_images/icons/SelectChevronDown";
import { useAppConfig } from "src/App/state/appConfig";

const Styles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  z-index:10;
  .navbar { 
    background-color: #F7F7F7; 
    height: 54px; 
    box-shadow: 0px 2px 3px 0px rgba(39, 50, 56, 0.10);
  }
  a, .navbar-nav, .navbar-light .nav-link {
    color: #9FFFCB;
    &:hover { color: white; }
  }
  .navbar-brand {
    font-size: 1.4em;
    color: #9FFFCB;
    padding: 0 20px;
    &:hover { color: white; }
  }
  .form-center {
    position: absolute !important;
    left: 25%;
    right: 25%;
  }

  form{
    display:flex;

    width: 800px;
    padding: 0 100px;
    > .searchBar{
      width: 100%;

      .react-select__indicators{
        display:none;
      }
    }
  }
`;

const NoNavStyles = styled.div`
  .navbar { background-color: transparent; height: 72px; }
  .navbar-brand {
    font-size: 1.4em;
    color: #9FFFCB;
    padding: 0 20px;
    &:hover { color: white; }
  }
`;

const StyledBrand = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height, or 214% */
  letter-spacing: 2.4px;
  color: #FFFFFF;
  padding: 15px 0;

  ${NoNavStyles} & {
    color: #273238;
  }

`;

/*
UserInfo Model Stuff
 */
const getUserInfoStateSlice = (state) => state.userInfo;

const isChatLoaded = createSelector(
  getUserInfoStateSlice,
  (state) => {
    return Object.keys(state.chat).length > 0
  }
)

const isProfileLoaded = createSelector(
  getUserInfoStateSlice,
  (state) => {
    return Object.keys(state.profile).length > 0
  }
)

const isChatLoading = createSelector(
  getUserInfoStateSlice,
  (state) => {
    return state.chatLoading
  }
)

const isProfileLoading = createSelector(
  getUserInfoStateSlice,
  (state) => {
    return state.profileLoading
  }
)

const getChatState = createSelector(
  getUserInfoStateSlice,
  (state) => {
    return state.chat
  })

const NavBarChat = () => {
  const dispatch = useDispatch();
  const { isLoading } = useAuth();

  /**
  const chatState = useSelector(getChatState);

  const chatLoaded = useSelector(isChatLoaded);
  const chatLoading = useSelector(isChatLoading);

  useEffect(()=>{
    if (!chatLoaded && !chatLoading) {
      dispatch(actions.getChatInitial());
    }
    if (Object.keys(chatState).length > 0){
      consoleLog("initialize Chat uuid " +chatState.uuid + " auth " + chatState.auth_key + " group " + chatState.channel_group );
      dispatch(chatActions.loadChatChannels(chatState.uuid, chatState.auth_key, chatState.channel_group));
    }
  }, [chatLoaded,chatLoading])
  */
  return (
    <div></div>
  )

}

const groupRoles = (roles) => {
  const groupedRoles = []
  roles.forEach((role) => {
    // just need the clinic, so dont worry about the role yet
    if (!groupedRoles.some((groupedRole) => groupedRole.clinic.id === role.clinic.id)) {
      groupedRoles.push(role);
    }
  })
  return groupedRoles;
}

const ClinicRoles = () => {
  const { user } = useAuth();
  const { formatMessage } = useIntl();
  const { data, isLoading, isFetching } = useClinicRoles({config: {refetchOnMount: true}});
  const clinic = useAppConfig(state => state.clinic); // current selected clinic from app config
  const setConfig = useAppConfig(state => state.setConfig); // set clinic in app config

  const defaultData = React.useMemo(() => [], [])

  const groupedRoles = groupRoles(data?.roles || defaultData); // group roles by clinic. return unique clinic

  useEffect(()=>{
    return () => {}
  }, [])

  if (groupedRoles.length <= 1  ) {
    return <></>
  } else {
    return (
      <Menu isLazy>
        <MenuButton>
          <Flex align={'center'}>
            <Text color="#405159" mr={'5px'}>
              {
                clinic ? (
                  clinic.name + (clinic.branch ? ` - ${clinic.branch}` : '') + (user?.is_staff ? ` - ${clinic.id}` : '')
                ) : (user?.is_staff) ? (
                  formatMessage({id: 'settings.selectClinic.all', defaultMessage: 'Filter by Clinic'})
                ) : (
                  groupedRoles[0].clinic.name + (groupedRoles[0].clinic.branch ? ` - ${groupedRoles[0].clinic.branch}` : '' )
                )
              }
            </Text>
            <SelectChevronDown height={15} width={15} />
          </Flex>
        </MenuButton>
        <MenuList>
          {user?.is_staff && (
            <MenuItem onClick={() => setConfig('clinic', null)}>
              {formatMessage({id: 'settings.selectClinic.all', defaultMessage: 'All Clinics'})}
            </MenuItem>
          )}
          {
            (groupedRoles).map((role) => {
              return (
                <MenuItem 
                  key={role.id} 
                  isDisabled={role.clinic.id == clinic?.id}
                  onClick={() => setConfig('clinic', role.clinic)}
                >
                  {role.clinic.name}{role.clinic.branch ? `- ${role.clinic.branch}` : ''} { user?.is_staff && ` - ${role.clinic.id}` }
                </MenuItem>
              )
            })
          }
        </MenuList>
      </Menu>
    )
  }

}

// check user profile and user chat
//
const LoggedInNavBar = () => {
  // eslint-disable-next-line no-unused-vars
  /*const { user } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  
  const profileLoaded = useSelector(isProfileLoaded);
  const profileLoading = useSelector(isProfileLoading);

  const chatLoaded = useSelector(isChatLoaded);
  const chatLoading = useSelector(isChatLoading);


  useEffect(()=>{
    if (!profileLoaded && !profileLoading){
      dispatch(actions.getProfile());
    }
  }, [profileLoaded, profileLoading])

  useEffect(()=>{
    if (profileLoaded && chatLoaded && (!profileLoading && !chatLoading)){
      dispatch(alertActions.clear());
    }
  }, [profileLoaded, profileLoading, chatLoading, chatLoaded])*/

  return(
    <Styles>
      <Navbar sticky="top" expand="lg">
        <Navbar.Brand href="/">
          <Image 
            w={'45px'}
            src={logo}
            alt="PDH logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Flex mt={['15px', 0]} grow={1} justify={'flex-end'} py={['20px', 0]} bg={['white', 'transparent']}>
            <ClinicRoles />
          </Flex>
          <Nav className="ml-auto">      
            {/*<Nav.Item><Nav.Link onClick={clickLogout} href="">Logout</Nav.Link></Nav.Item>*/}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  )

}

const NavigationBar = () => {
  // currentlocation
  const location = useLocation();
  const params = useParams();

  var currentLocation = location.pathname

  useEffect(()=>{
      //consoleLog("location ", location);
      //consoleLog("params ", params.session_id);
  }, [params.session_id])

  if (currentLocation.startsWith("/videochat")){
    return(
      <div></div>
    )
  }
  else if (findKey(URLS, {'url': currentLocation, 'navbar': false})) {
    return(
      <NoNavStyles>
        <Navbar sticky="top" expand="lg">
        <Navbar.Brand href="/">
          <Image 
            w={'45px'}
            src={logo}
            alt="PDH logo"
          />
        </Navbar.Brand>
          </Navbar>
      </NoNavStyles>
    )
  } else {
    return (
      <LoggedInNavBar />
    )
  }
}

export { NavigationBar };
