import { useQuery, useQueryClient } from 'react-query';
import { queryConfig, queryClient } from 'src/_libs/react-query';

import { fetchUserRequestDetails } from './services';

import { toString } from 'lodash';

export const QUERY_KEY_NAME = 'Admin_UserRequestDetails';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const getQueryData = (requestId) => {
    const queryClient = useQueryClient();
    return queryClient.getQueryData([QUERY_KEY_NAME, {id: toString(requestId)}]);
}

export const queryRequest = async ({ requestId }) => {
    return fetchUserRequestDetails(requestId);
}

export const updateUserRequestDetails = (requestId, newData) => {
    queryClient.setQueryData([QUERY_KEY_NAME, {id: toString(requestId)}], (oldData) => {
        return {
            ...oldData,
            ...newData
        }
    })
}

export const useUserRequestDetails = ({ requestId, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!requestId,
        queryKey: [QUERY_KEY_NAME, {id: toString(requestId)}],
        queryFn: () => queryRequest({requestId}),
      });
}