import React from 'react';
import { Box, Flex, Modal, Text, SimpleGrid, HStack, IconButton, Button, ModalOverlay, ModalContent, ModalHeader, MenuOptionGroup, MenuItemOption,
    Center, ModalCloseButton, ModalBody, Select, Menu, MenuButton, MenuList, InputGroup, InputLeftElement, Spinner, Show,
    useDisclosure, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Input  } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MembershipSearch } from '../components/MemberSearch';
import { WrappedPage } from 'src/PageComponents/WrappedPage';
import { PageHeader } from '../components/PageHeader';
import { useAppConfig } from 'src/App/state/appConfig';
import { useSearchParamsObject } from 'src/_hooks/useSearchParams';
import { useHistory } from 'react-router-dom';
import { useTreatmentPlanCollection } from '../api/getTreatmentPlanCollection';
import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import PaginationNext from 'src/_images/icons/PaginationNext';
import ThreeVerticalDotsIcon from 'src/_images/icons/ThreeVerticalDotsIcon';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import ArrowForwardIcon from 'src/_images/icons/ArrowForwardIcon';
import SearchIcon from 'src/_images/icons/SearchIcon';
import { NoReportsFoundIcon } from 'src/_images/icons/NoReportsFound';


const MembershipSearchModal = ({isOpen, onClose}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} scrollBehavior={['inside']} >
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent minW={["100%", "700px"]} bg="#f7f9fa">
                <ModalHeader>
                <Center>
                        <FormattedMessage 
                            id={'treatmentPlanCollection.findMember.title'}
                            defaultMessage={'Find Member'}
                        />
                </Center>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Box>
                        <MembershipSearch />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, isLoading}) => {
    return (
        <HStack spacing={3} >
            <IconButton 
                isLoading={isLoading}
                icon={<PaginationPrevious />} 
                isDisabled={!canPreviousPage}
                onClick={previousPage}
                borderRadius={'5px'}
            />
            <Select 
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) : 0
                    setPageIndex(page)
                }}
                isDisabled={total < 2}
            >
                {Array.from({length: total}, (_, i) => (
                    <option key={i} value={i} selected={pageIndex == i}>
                        {i + 1}
                    </option>
                ))}
            </Select>
            <IconButton 
                isLoading={isLoading}
                icon={<PaginationNext />} 
                isDisabled={!canNextPage}
                borderRadius={'5px'}
                onClick={nextPage}
            />
        </HStack>
    
    )
}

const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "short",
        day: "numeric"
    }).format(new Date(date));
};

const actionsAccessorFn = ({row, handleCancelPlan, handleArchivePlan, handleRejectPlan, handleStartPlan}) => {
    // eslint-disable-next-line no-unused-vars
    const MENU_ITEM_ATTRS = {
      py: '8px',
    }
    const treatment_plan = row.original;
    // cancleed or omplete, archive\
  
    // eslint-disable-next-line no-unused-vars
    const handleCancel = () => {
      handleCancelPlan(treatment_plan?.unique_id);
    }
  
    // eslint-disable-next-line no-unused-vars
    const handleArchive = () => {
      handleArchivePlan(treatment_plan?.unique_id);
    }
  
    // eslint-disable-next-line no-unused-vars
    const handleRejected = () => {
      handleRejectPlan(treatment_plan?.unique_id);
    }
  
    // eslint-disable-next-line no-unused-vars
    const handleStart = () => {
      handleStartPlan(treatment_plan?.unique_id);
    }
    return <></>
    
    /*
    if (treatment_plan?.status == "completed" || treatment_plan?.status == "canceled" ){
      return (
        <Flex onClick={(event) => event.stopPropagation()}>
          <Menu>
            <MenuButton p={'8px'}>
              <ThreeVerticalDotsIcon />
            </MenuButton> 
            <MenuList>
              <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'}  onClick={handleArchive} icon={<ArchiveIcon  color={'#3E5159'}  />}>
                <FormattedMessage 
                  id={'treatmentCollectionPage.treatmentList.actions.archive'}
                  defaultMessage={'Archive Request'}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      )
    } else if (treatment_plan?.status == "planned" ) {
      
        return (
          <Flex onClick={(event) => event.stopPropagation()}>
            <Menu>
              <MenuButton p={'8px'}>
                <ThreeVerticalDotsIcon />
              </MenuButton>
              <MenuList>
                <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'} onClick={handleStart} icon={<CheckIcon width={10} height={10} color={'#3E5159'} />}>
                  <FormattedMessage 
                    id={'treatmentCollectionPage.treatmentList.actions.start'}
                    defaultMessage={'Start Treatment'}
                  />
                </MenuItem>
                <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'} onClick={handleRejected} icon={<CloseIcon width={10} height={10} color={'#3E5159'} />}>
                  <FormattedMessage 
                    id={'treatmentCollectionPage.treatmentList.actions.reject'}
                    defaultMessage={'Reject Treatment'}
                  />
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )
      } else if (treatment_plan?.status == "in_progress") {
      
      return (
        <Flex onClick={(event) => event.stopPropagation()}>
          <Menu>
            <MenuButton p={'8px'}>
              <ThreeVerticalDotsIcon />
            </MenuButton>
            <MenuList>
              <MenuItem {...MENU_ITEM_ATTRS} color={'#3E5159'} onClick={handleCancel} icon={<CloseIcon width={10} height={10} color={'#3E5159'} />}>
                <FormattedMessage 
                  id={'treatmentCollectionPage.treatmentList.actions.cancel'}
                  defaultMessage={'Cancel Request'}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      )
    } else {
      return (
        <Flex></Flex>
      )
    }*/
  }

const PageTable = ({data, isLoading, pagination, setPagination, onEditRow, handleCancelPlan, handleArchivePlan, handleRejectPlan, handleStartPlan}) => {
    const { formatMessage } = useIntl();
    const columns = React.useMemo(
        () => [
            {
                header: 'Treatment ID',
                id: 'treatment_id',
                accessorFn: row => row.treatment_uid,
            },
            {
                header: 'Member ID',
                id: 'membership',
                accessorFn: row => row.membership_number,
            },
            {
                header: 'Name',
                id: 'name',
                accessorFn: row => formatMessage({
                    id: 'format.fullName' , 
                    defaultMessage: '{givenName} {familyName}'
                }, {
                    givenName: row.patient?.first_name, familyName: row.patient?.last_name
                })
            },
            {
                header: 'Date of Birth',
                id: 'date_of_birth',
                accessorFn: row => formatDate(row.patient?.date_of_birth),
            },
            {
                header: 'Created Date',
                id: 'created_date',
                accessorFn: row => formatDate(row.created_date),
            },
            {
                header: 'Status',
                id: 'status',
                accessorKey: 'status',
            },
            {
                header: '',
                id: 'actions',
                cell: ({row}) => actionsAccessorFn({row, handleCancelPlan, handleArchivePlan, handleRejectPlan, handleStartPlan})
            }
        ],
        []
    )

    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data?.treatment_plans || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })


    const handleRowClick = (row) => {
        onEditRow(row?.unique_id)
    }

    return (
      <>
        <TableContainer
            maxWidth={{ base: "calc(100vw - 40px)", md: "100%" }} // Adjust width for mobile and desktop
            whiteSpace={{ base: "nowrap", md: "normal" }} // Prevent wrapping on mobile
            pt={[ '20px', 0]}
        >
            <Table mt={[0, '20px']}
              variant="striped"
              size={["sm", "xl"]}
              sx={{
                "th": {
                    px: 4
                },
                "td": {
                  py: '20px',
                  px: 6
                },
                "@media (max-width: 768px)": {
                  "thead, tbody, tr, th, td": {
                    display: "block",
                    width: "100%",
                  },
                  thead: {
                    display: "none", // Hide headers on mobile
                  },
                  tr: {
                    marginBottom: "1rem",
                    border: "1px solid #e2e8f0", // Add border for separation
                  },
                  td: {
                    display: "flex",
                    alignItems: "center",
                    padding: "0.5rem",
                    textAlign: "left",
                    borderBottom: "1px solid #e2e8f0",
                    whiteSpace: "normal", // Allow wrapping
                    wordWrap: "break-word",
                    border: 'none',
                    py: 2,
                    px: 8
                  },
                  "td:first-child": {
                    pt: 6
                  },
                  "td::before": {
                    content: "attr(data-label)", // Display column header as label
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    marginRight: "0.5rem",
                    color: "#718096",
                  },
                },
              }}
            >
                <Thead>
                    {(table.getHeaderGroups() || []).map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody
                  overflow={{ base: "auto", md: "unset" }} // Scrollable on mobile, full-width on desktop
                >
                    {table.getRowModel().rows.length > 0 ? (table.getRowModel().rows.map(row => {
                        return (
                        <Tr key={row.id}  onClick={() => handleRowClick(row.original)} _hover={{cursor: 'pointer', bg: '#F7F7F7'}}>
                            {row.getVisibleCells().map(cell => {
                            return (
                                <Td 
                                  key={cell.id}
                                  data-label={cell.column.columnDef.header}
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Td>
                            )
                            })}
                        </Tr>
                        )
                    })) : isLoading ? (
                        <Tr>
                            <Td colSpan={[columns.length]} justifyContent={['center', 'space-between']}>
                                <Box textAlign={'center'} mt={'20px'}>
                                  <Spinner 
                                      size='xl'
                                      thickness='4px'
                                      speed='0.65s' 
                                      color='#44C5A6' 
                                  />
                                </Box>
                            </Td>
                        </Tr>
                    ) :(
                      <>
                        <Tr />
                        <Tr>
                            <Td colSpan={[columns.length]} justifyContent={['center', 'space-between']}>
                                <Box textAlign={'center'} mt={'20px'}>
                                  <Flex align={['center']} direction={'column'}>
                                      <NoReportsFoundIcon />
                                      <Text textAlign={'center'} mt={'20px'} color={'#6B8A99'}>
                                        <FormattedMessage
                                            id={'treatmentPlanCollection.noResults'}
                                            defaultMessage={'No results found'}
                                        />
                                      </Text>
                                  </Flex>
                                </Box>
                            </Td>
                          </Tr>
                      </>
                    )
                    }
                </Tbody>
            </Table>
            </TableContainer>
            {
              table.getRowModel().rows.length > 0 && (
                <Flex w="full" mt={'50px'} justify={'flex-end'}>
                    <Pagination 
                        isLoading={isLoading}
                        canPreviousPage={table.getCanPreviousPage()}
                        canNextPage={table.getCanNextPage()}
                        pageIndex={table.getState().pagination.pageIndex}
                        total={table.getPageCount()}
                        nextPage={table.nextPage}
                        previousPage={table.previousPage}
                        setPageIndex={table.setPageIndex}
                    />
                </Flex>
            )}
            </>
    )
}


const MENU_ITEM_ATTRS = {
  py: '8px',
}

// eslint-disable-next-line no-unused-vars
const ThreeDotMenu = ({handleFilter}) => {
    const searchParams = useSearchParamsObject();
    const { formatMessage } = useIntl();
    return (
        <Menu>
            <MenuButton px={'15px'}>
                <ThreeVerticalDotsIcon />
            </MenuButton>
            <MenuList>
              <MenuOptionGroup 
                fontWeight={700} 
                fontSize={14} 
                textAlign={'left'} 
                title={formatMessage({id: 'treatmentPlanDetails.filter.menu.filterBy', defaultMessage: 'Filter By'})}
                onChange={(value) => handleFilter(value)}
              >
                <MenuItemOption 
                    {...MENU_ITEM_ATTRS} 
                    color={'#3E5159'}  
                    value={'rejected'}
                    isChecked={searchParams?.status == 'rejected'}
                    //icon={<ArchiveIcon />}
                >
                    <FormattedMessage 
                        id={'treatmentPlanDetails.filter.rejected'}
                        defaultMessage={'Rejected'}
                    />
                </MenuItemOption>
                <MenuItemOption 
                    {...MENU_ITEM_ATTRS} 
                    color={'#3E5159'}  
                    value={'canceled'}
                    isChecked={searchParams?.status == 'canceled'}
                    //icon={<ArchiveIcon />}
                >
                    <FormattedMessage 
                        id={'treatmentPlanDetails.filter.canceled'}
                        defaultMessage={'Canceled'}
                    />
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
        </Menu>
    )
}

const FilterHeader = () => {
    const history = useHistory();
    const searchParams = useSearchParamsObject();
    const [ searchInput, setSearchInput ] = React.useState('');
    const [ isShowMore, setIsShowMore ] = React.useState(false);

    const handleSearch = () => {
      const params = searchParams;
      if (searchInput) {
        params.q = searchInput;
        const newParams = new URLSearchParams(params);
        history.push({search: newParams.toString()})
      } else {
        delete params.q;
        const newParams = new URLSearchParams(params);
        history.push({search: newParams.toString()})
      }
    }

    const handleClear = () => {
      setSearchInput('');
      const params = searchParams;
      delete params.q;
      const newParams = new URLSearchParams(params);
      history.push({search: newParams.toString()})
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearch();
            // Perform your desired action here
        }
    };

    const handleFilter = (status) => {
      const params = searchParams;
      //if (status == 'all') {
      //  delete params.status;
      //  const newParams = new URLSearchParams(params);
      //  history.push({search: newParams.toString()})
      //} else {
        params.status = status;
        const newParams = new URLSearchParams(params);
        history.push({search: newParams.toString()})
      //}
    }  

    const ShowMoreFilters = ({isShowMore}) => {
      if (isShowMore) {
        return (
          <HStack pl={[0, '10px']} pt={['10px', 0]}>
            <Button size={'xl'} onClick={() => handleFilter('rejected')} variant={searchParams?.status == 'rejected' ? 'solid': 'outline'}>
              <FormattedMessage
                  id={'treatmentPlanCollection.filter.rejected'}
                  defaultMessage={'Rejected'}
              />
            </Button>
            <Button size={'xl'} onClick={() => handleFilter('canceled')} variant={searchParams?.status == 'canceled' ? 'solid': 'outline'}>
              <FormattedMessage
                  id={'treatmentPlanCollection.filter.canceled'}
                  defaultMessage={'Canceled'}
              />
            </Button>
            <Button size={'xl'} onClick={() => handleFilter('all')} variant={searchParams?.status == 'all' || searchParams?.status == 'all' ? 'solid': 'outline'}>
              <FormattedMessage
                  id={'treatmentPlanCollection.filter.all'}
                  defaultMessage={'All'}
              />
            </Button>
          </HStack>
        )
      } else {
        return(
          <Flex pl={[0, '5px']} py={['15px', 0]}>
            <Button ml={'10px'} size={'md'} onClick={() => setIsShowMore(true)} variant={'link'}>
              <FormattedMessage
                  id={'treatmentPlanCollection.filter.showMore'}
                  defaultMessage={'More Filters'}
              />
            </Button>
          </Flex>
        )
      }
    }

    return (
        <Box maxW={['100%', 'unset']}>
            <Box pt={'30px'}>
              <Show below="md">
                <Box pb={['10px', 0]}>
                  <Text color={'#9B9B9B'} textAlign={['left']}>
                    <FormattedMessage 
                        id={'treatmentPlanCollection.filter.title'}
                        defaultMessage={'Filter By'}
                    />
                  </Text>
                </Box>
              </Show>
              <HStack maxW={'full'} wrap={['wrap', 'nowrap']}>
                <Button size={'xl'} onClick={() => handleFilter('pending')} variant={(searchParams?.status == 'pending' || !searchParams?.status) ? 'solid': 'outline'}>
                  <FormattedMessage
                      id={'treatmentPlanCollection.filter.pending'}
                      defaultMessage={'Pending'}
                  />
                </Button>
                <Button size={'xl'} onClick={() => handleFilter('completed')} variant={searchParams?.status == 'completed' ? 'solid': 'outline'}>
                  <FormattedMessage
                      id={'treatmentPlanCollection.filter.completed'}
                      defaultMessage={'Completed'}
                  />
                </Button>
                <ShowMoreFilters isShowMore={isShowMore} />
              </HStack>
            </Box>
            <InputGroup mt={'20px'}>
              <InputLeftElement>
                <SearchIcon width={20} height={20} />
              </InputLeftElement>
              <Input 
                  placeholder={'Search by Treatment or Member'}
                  w={'full'}
                  value={searchInput}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setSearchInput(e.target.value)}
              />
            </InputGroup>
            <Box textAlign={'left'} pt={'10px'}>
              <Text color={'#6B8A99'} ml={'20px'}>
                {
                  searchParams.q && (
                    <Flex>
                      <Text>
                      <FormattedMessage 
                          id={'treatmentPlanCollection.filter.search.query'}
                          defaultMessage={'Results for "{search}"'}
                          values={{search: searchParams.q}}
                      />
                      </Text>
                      <Button ml={'5px'} size={'sm'} variant={'link'} onClick={handleClear}>
                        <FormattedMessage 
                            id={'treatmentPlanCollection.filter.search.clear'}
                            defaultMessage={'Clear'}
                        />
                      </Button>
                    </Flex>
                  )
                }
              </Text>
            </Box>
        </Box>
    )
}

export const TreatmentPlanCollectionPage = () => {
    const searchParams = useSearchParamsObject();
    const history = useHistory();
    const clinic = useAppConfig(state => state.clinic?.id); // current selected clinic from app config
    const { isOpen, onClose, onOpen } = useDisclosure()
    const PAGE_SIZE = 20;
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    })

    const { data, isLoading, isFetching } = useTreatmentPlanCollection({
        ...searchParams, offset: pagination.pageIndex, pg_sz: pagination.pageSize, clinic
    });

    const onEditRow = (id) => {
        history.push(`/treatmentPlans/${id}`);
    }

    return (
        <WrappedPage>
            <Box pr={['0px', '40px']} pb={['100px', '50px']}>
                <MembershipSearchModal isOpen={isOpen} onClose={onClose} />
                <SimpleGrid templateColumns={['1fr', '8fr 1fr']} w={['100%', 'unset']}>
                    <PageHeader page="treatmentPlanCollection" />
                    <Flex justify={['flex-start', 'flex-end']} align={'center'} mt={['15px', 0]}>
                        <Button onClick={onOpen} rightIcon={<ArrowForwardIcon />}>
                            <FormattedMessage 
                                id={'treatmentPlanCollection.createTreatmentPlan.button'}
                                defaultMessage='Find Member & Create Treatments'
                            />
                        </Button>
                    </Flex>
                </SimpleGrid>
                <FilterHeader />
                <PageTable 
                    data={data} 
                    isLoading={isLoading || isFetching} 
                    pagination={pagination} 
                    //handleCancelPlan={handleCancelPlan}
                    //handleArchivePlan={handleArchivePlan}
                    //handleRejectPlan={handleRejectPlan}
                    //handleStartPlan={handleStartPlan}
                    onEditRow={onEditRow}
                    setPagination={setPagination} />

            </Box>
        </WrappedPage>
    )
}