import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchClinicBilling } from './_services';

export const QUERY_KEY_NAME = 'ClinicBillingUrl';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async () => {
    // filter through the input params and remove any empty values
    return fetchClinicBilling()
}

export const useDentistBilling = () => {
    return useQuery({
        ...newQueryConfig,
        queryKey: [QUERY_KEY_NAME, {}],
        queryFn: () => queryRequest(),
      });
}