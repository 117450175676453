import { useMutation } from 'react-query';

import { toString } from 'lodash';
import { queryClient } from 'src/_libs/react-query';
import { archivePatientRequest } from './services';
import { QUERY_KEY_NAMES } from './constants';
import { QUERY_KEY_NAMES as REPORT_QUERY_KEY_NAMES } from 'src/Reports/api/constants';

export const cancelQuery = ({ id, clinic }) => {
    //return axios.post(apiRoutes.appointmentsCreate, data);
    return archivePatientRequest(id, clinic)
};

export const useArchiveDentistRequest = ({ id, queryParams, clinic, config }) => {
  return useMutation({
    mutationKey: [QUERY_KEY_NAMES.ARCHIVE_DENTISTREQUEST, { id }],
    onMutate: async () => {
      // cancel any ongoing queries
      await queryClient.cancelQueries([QUERY_KEY_NAMES.ARCHIVE_DENTISTREQUEST, { id }]);
    },
    // eslint-disable-next-line no-unused-vars
    onSuccess: (_data) => {
      // remove from user request query
      queryClient.setQueryData([QUERY_KEY_NAMES.GET_DENTISTREQUEST_COLLECTION, queryParams], (oldData) => {
        const newData = (oldData.request_list || []).filter((request) => request.unique_id !== id);
        return {
          request_list: newData,
        };
      })
      queryClient.invalidateQueries([REPORT_QUERY_KEY_NAMES.GET_REQUEST, { id: toString(id) }]);  
    },
    mutationFn: () => cancelQuery({id, clinic}),
    ...config
  });
};
