import './index.css';
import React from 'react';
import { useEffect, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import EditorJS from '@editorjs/editorjs';
import { useReportStore } from '../../Reports/state/report';
import { useIntl } from 'react-intl';

import { EDITOR_TOOLS, EDITOR_BOX_PROPS } from './constants';

export const Editor = ({ editorInstance=null, data={}, setEditorInstance=null}) => {
    const editorRef = useRef(null);
    const editorInstanceRef = useRef(null); // To store the EditorJS instance
    const { formatMessage } = useIntl();
    const { setIsSubmitDisabled } = useReportStore();  
    useEffect(() => {
    
      if (editorRef.current && editorInstance === null && !editorInstanceRef.current) {
        const instance = new EditorJS({
          holder: editorRef.current,
          minHeight: 100,
          data: data,
          placeholder: formatMessage({
            id: 'editor.report.placeholder',
            defaultMessage: 'Click on a tooth and category to add a note',
          }),
          tools: EDITOR_TOOLS,
        });

        // Store the instance in the ref
        editorInstanceRef.current = instance;
  
        setEditorInstance(instance);
        if ((data?.blocks || []).length > 0) {
            setIsSubmitDisabled(false);
        }
        // Cleanup to destroy the EditorJS instance
        return () => {
          // Cleanup: Destroy the instance
          if (instance) {
            try{
              instance.destroy();
            } catch {
              //console.error('Editor ', 'Error destroying the editor instance');
              
            }
          }
        };
      }
    }, [editorRef.current, editorInstance, setEditorInstance, setIsSubmitDisabled]);


    return (
        <Box w={['full']}>
            <Flex mx={'auto'} {...EDITOR_BOX_PROPS}>
                <div 
                    style={{
                      height: '300px', 
                      overflowX: 'hidden', 
                      overflowY: 'auto'
                    }}  
                    ref={editorRef} 
                    key={`editorRef`} 
                />
                {/* You can use editorData elsewhere in this component */}
            </Flex>         
        </Box>

    );
}
