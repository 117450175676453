import React from 'react';
import { Box, Grid, GridItem, Text, Flex, VStack, DrawerCloseButton, Divider,
    Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody

} from '@chakra-ui/react';
import { useFeeScheduleCollection } from '../api/getFeeScheduleCollection';
import { useAppConfig } from 'src/App/state/appConfig';
import { CenteredSpinner } from 'src/_components/Loaders/CenteredSpinner';
import { numberToFixedDecimal } from '../utils';
import { FormattedMessage } from 'react-intl';


const getDiscountedString = (item) => {
    if (item){
        let diff = numberToFixedDecimal(item?.average_price )- numberToFixedDecimal(item?.base_price);
        let discount = Math.round( diff / numberToFixedDecimal(item?.average_price) * 100);
        return {
            diff: numberToFixedDecimal(diff),
            discount: discount
        }
    } else {
        return {
            diff: null,
            discount: null
        }
    }
}

const FeeItem = ({item, currencySymbol}) => {
    console.log("item in FeeItem", item);
    const { diff, discount } = getDiscountedString(item);

    const TEXT_PROPS = {
        fontSize: 14
    }

    return (
        <>
            <Box px={['10px']} py={['5px']}>
                <Grid templateColumns={['1fr']}>
                        <GridItem>
                            <Text fontSize={18}>
                                {item.treatment_code.name} 
                                <Box ml={'4px'} as={'span'} fontStyle={'italic'} color={'gray.400'}>{`(${item.treatment_code.code})`}</Box>
                            </Text>
                        </GridItem>
                </Grid>
                
                <Grid>
                    <GridItem>
                        <Flex>
                            <Text {...TEXT_PROPS} textDecoration="line-through" color={'#9B9B9B'} >
                                {`${currencySymbol} ${item.average_price}`}
                            </Text>
                            <Text {...TEXT_PROPS} ml={['10px']} color={'#E95252'}>
                                { diff && discount ? `-${currencySymbol}${diff} ( ${discount}% )` : ''}
                            </Text>
                        </Flex>
                    </GridItem>
                </Grid>
                
                <Grid>
                    <GridItem>
                        <Flex>
                            <Text {...TEXT_PROPS} fontSize={16}>
                                {`${currencySymbol} ${item.base_price}`}
                            </Text>
                        </Flex>
                    </GridItem>
                </Grid>
            </Box>
            <Divider />     
        </>
    )
}

export const FeeScheduleList = ({plan, currencySymbol}) => { 
    const clinic = useAppConfig(state => state.clinic);
    const { data, isFetching, isLoading } = useFeeScheduleCollection({plan: plan, clinic: clinic, pg_sz: 1000});


    if (isLoading || isFetching ) {
        return (
            <Box>
                <CenteredSpinner />
            </Box>
        )
    }

    return (
        <VStack align={['flex-start']} spacing={['15px']}>
            {(data?.treatment_codes || []).map((item, index) => <FeeItem key={index} item={item} currencySymbol={currencySymbol} />)}
        </VStack>
    )
}

// eslint-disable-next-line no-unused-vars
export const FeeScheduleListDrawer = ({plan, planName, currencySymbol, onClose, isOpen}) => {

    return (
        <Drawer placement={'right'} onClose={onClose} isOpen={isOpen} size={'sm'}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth='1px' py={['10px', '20px']}>
                {
                    planName ? `${planName} ` : ''
                }
                <FormattedMessage 
                    id="feeScheduleList.feeSchedule.title"
                    defaultMessage="Fee Schedule"
                />
            </DrawerHeader>
            <DrawerBody>
              <FeeScheduleList 
                plan={plan}
                currencySymbol={currencySymbol}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
    )
}