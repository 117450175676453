

import { useQuery } from 'react-query';
import { queryConfig, queryClient } from 'src/_libs/react-query';
import { toString } from 'lodash';

import { fetchClinicDetails } from './services';

export const QUERY_KEY_NAME = 'Admin_ClinicDetails';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (clinicId) => {
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    //const searchParams = new URLSearchParams(paramsObj)
    return fetchClinicDetails(clinicId)
}

export const updateClinicDetails = (clinicId, newData) => {
    queryClient.setQueryData([QUERY_KEY_NAME, {id: toString(clinicId)}], (oldData) => {
        return {
            ...oldData,
            ...newData
        }
    })
}

export const useClinicDetails = ({ clinicId }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!clinicId,
        queryKey: [QUERY_KEY_NAME, {id: toString(clinicId)}],
        queryFn: () => queryRequest(clinicId), // in brackets because im parsing the object in queryRequest
      });
}