import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchCategoryTemplates } from './services';

export const QUERY_KEY_NAME = 'REPORTCATEGORYTEMPLATES';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async ({ category, reportType, locale=null }) => {
    return fetchCategoryTemplates(category, reportType, locale);
}

export const useReportCategoryTemplates = ({ category, reportType=null, locale=null, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!category,
        queryKey: [QUERY_KEY_NAME, {category, locale, reportType}],
        queryFn: () => queryRequest({category, locale, reportType}),
      });
}