/* eslint-disable no-unused-vars */

import React from 'react';
import { authHeader } from 'src/_helpers/auth-header';
import Select, { components } from 'react-select';
import { API_URLS } from 'src/_config';

import SearchIcon from 'src/_images/icons/SearchIcon';
import AsyncSelect from 'react-select/async';
import { 
  Box, 
  Flex,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useFeeScheduleCollection } from '../api/getFeeScheduleCollection';


const SELECT_HEIGHT = 32;

// eslint-disable-next-line no-unused-vars
const DropdownIndicator = ({ children, ...props}) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon color={props?.color} />
      </components.DropdownIndicator>
    )
  }
  
  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <FormattedMessage id="format.search.noOptions" defaultMessage="No results found" />
      </components.NoOptionsMessage>
    );
  };

  
const errorStyles = {
    control: (provided, state) => {
      return { ...provided, 
        height: 45,
        color: '#405159',
        backgroundColor: 'transparent',
        borderRadius: 30,
        boxShadow: state.isFocused ? '0 0 0 1px #E95252' : 'none' , 
        borderColor: '#E95252', 
        "&:hover": {
          borderColor: '#E95252'
        },
      }
    },
  }

const defaultSelectStyles = {
    dropdownIndicator: (provided, state) => {
      const display = state.hasValue ? 'none': 'block';
      const color = state.isDisabled ? '#A0B1BA' : '#405159';
      return { ...provided, padding: '5px', height: SELECT_HEIGHT-2, display, color };
    },
    indicatorsContainer: (provided, state) => {
    const color = state.isDisabled ? '#A0B1BA' : '#405159';
      return { ...provided, padding: '0 10px', height: SELECT_HEIGHT-2, color }
    },
    indicatorSeparator:  (provided) => {
      const display = 'none';
      return { ...provided, display };
    },
    placeholder: (provided) => {
      return { ...provided, color: '#7A8F99' }
    },
    valueContainer: (provided, state) => {
      return { 
        ...provided, 
        padding: '0 10px', 
        color: state.isDisabled ? '#A0B1BA' : '#405159',
        height: SELECT_HEIGHT-2
      }
    },
    control: (provided, state) => {
      return { ...provided, 
        boxShadow: state.isFocused ? '0 0 0 1px #405159' : 'none' , 
        borderColor: state.isFocused? '#405159' :'#C7D3D9', 
        minHeight: SELECT_HEIGHT,
        height: SELECT_HEIGHT,
        color: state.isDisabled ? '#A0B1BA' : '#405159',
        background: state.isDisabled ? 'rgba(199, 211, 217, 0.40)' : 'transparent', 
        fontSize: '12px',
        backgroundColor: 'transparent',
        borderRadius: 5,
        "&:hover": {
          borderColor: '#405159'
        },
        "&:focus": {
          borderColor: '#405159'
        },
      }
    },
    menu: (provided, ) => {
      return { ...provided,
        zIndex: 10
      }
    },
    menuPortal: (provided, ) => {
      return { ...provided,
        zIndex: 10
      }
    },
    
  
  }

export const TreatmentCodeSearchLocalSearch = ({value, onSelect, plan, error=null, disabled=false, placeholder=null, clinic=null}) => {
    const { data, isFetching, isLoading } = useFeeScheduleCollection({plan: plan, clinic: clinic, pg_sz: 1000});

    const formatOptionLabel = ({value, label, data}) => {
        if (value?.length === 0 || value === '0' || value === 0){
            return (
                <Flex align="center">
                    <Box  fontFamily="Proxima Nova" mr={5}>{label}</Box>
                </Flex>
            )
        } else if (data?.code){
            return(
                <Flex align="center" justify="flex-start">
                    <Flex direction="column">
                        <Tooltip label={data?.code_description} zIndex={999} placement={'auto-start'}>
                            <Text mr={'5px'} color={disabled ? '#A0B1BA' : '#405159'}>
                                {`${data?.name}`} 
                                <Box as={'span'} textColor={'gray.400'} fontStyle={'italic'}>
                                {` (${data?.code})`}</Box>
                            </Text>
                        </Tooltip>
                    </Flex>
                </Flex>
            )
        } else {
            return (
                <Flex align="center">
                    <Box  fontFamily="Proxima Nova" mr={5}>{label}</Box>
                </Flex>
            )
        }
    }


    const onChange = (val) => {
        onSelect(val)
    }

    const options = (data?.treatment_codes || []).map((item) => ({value: item.id, label: `${item.code} : ${item.name}`, data: item}))
   
    return (
        <Select
            defaultValue={value}
            name={'treatment_code'}
            options={options} // Use the custom loadOptions
            isLoading={isFetching || isLoading}
            placeholder={placeholder}
            isClearable={true}
            isMulti={false}
            isDisabled={disabled}
            onChange={inputValue => onChange(inputValue)}
            backspaceRemovesValue={false}
            styles={ error 
                ? {...defaultSelectStyles, ...errorStyles} 
                : {...defaultSelectStyles}
            }
            formatOptionLabel = {formatOptionLabel}
            isOptionDisabled={(option) => option?.data?.is_active === false}
            components={{DropdownIndicator, NoOptionsMessage}}
        />
    )

}

export const TreatmentCodeSearch = ({onSelect, plan, error=null, disabled=false, placeholder=null, clinic=null}) => {

    const formatOptionLabel = ({value, label, data}) => {

        if (value?.length === 0 || value === '0' || value === 0){
            return (
                <Flex align="center">
                    <Box  fontFamily="Proxima Nova" mr={5}>{label}</Box>
                </Flex>
            )
        } else if (data){
            return(
                <Flex align="center">
                    <Flex direction="column">
                        <Box fontFamily="Proxima Nova" mr={5}>
                            {data?.code + ' - ' + data?.name}
                        </Box>
                    </Flex>
                </Flex>
            )
        } else {
            return (
                <Flex align="center">
                    <Box  fontFamily="Proxima Nova" mr={5}>{label}</Box>
                </Flex>
            )
        }
    }


    const onChange = (val) => {
        onSelect(val);
      }

      
    const handleOptionsLoading = (input) => {
      if (!input){
        return Promise.resolve({ options: []})
      }
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      let url = API_URLS.fetchFeeScheduleCollection.url+`?q=${input}&plan=${plan}`;
      if (clinic){
          url = url + `&clinic=${clinic}`
      }
      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((json) => {
          const options = json.treatment_codes.map((obj) => {
            return {value: obj.id,
                    label: obj.code,
                    data: {
                      ...obj
                    },
            }
          })
          return [ ...options ];
        });
    }
  


  return (
    <AsyncSelect
        loadOptions={handleOptionsLoading}
        //options={(data?.treatment_codes || []).map((item) => ({value: item.id, label: item.code, data: item}))} // Use the custom loadOptions
        placeholder={placeholder}
        isClearable={true}
        isMulti={false}
        isDisabled={disabled}
        onChange={inputValue => onChange(inputValue)}
        backspaceRemovesValue={false}
        debounceInterval={1000}
        formatOptionLabel={formatOptionLabel}
        styles={ error 
          ? {...defaultSelectStyles, ...errorStyles} 
          : {...defaultSelectStyles}
        }
        isOptionDisabled={(option) => option?.data?.is_active === false}
        components={{DropdownIndicator, NoOptionsMessage}}
        cacheOptions={true}
    />
  )

}
