import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchReportRecommendations } from './services';
import { toString } from 'lodash';

export const QUERY_KEY_NAME = 'ReportRecommendations';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async ({ reportUid, clinic, locale=null }) => {
    return fetchReportRecommendations(reportUid, clinic, locale);
}

export const useReportRecommendations = ({ reportUid, clinic, locale=null, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        queryKey: [QUERY_KEY_NAME, {id: toString(reportUid), locale}],
        queryFn: () => queryRequest({reportUid, clinic, locale}),
      });
}