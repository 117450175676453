/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { FormControl } from '@chakra-ui/react';
import { toUpper, capitalize } from 'lodash';
import { defaultSelectStyles, errorStyles  } from './common';

class SelectInput extends React.Component{
	render () {
		return (
				<Select
					{...this.props}
                    ref={this.props.inputRef }
                    autoload={true}
					backspaceRemovesValue={true}
				/>
		);
	}
};

function isObject(value) {
  return value !== null && typeof value === 'object';
}

function getSelectValue(value, options=[], allowUnfoundOption=false) {
  if (!isObject(value)) {
    var foundValue =  options.find(obj => (obj.value == value))
    if (foundValue) {
      return foundValue
    }
    if (value && allowUnfoundOption) {
      return {value: value, label: value, unfound: true}
    } else {
      return undefined
    }
  }
  return value;
}

export const SelectFieldFromDefaultNoInput = ({
  name,
  meta, 
  value,
  options,
  isError,
  menuPortalTarget=document.body,
  multi=true,
  onChange=null,
  isDisabled=false,
  clearable=true,
  getInputValue=null,
  formatOption=null,
  allowUnfoundOption=false,
  ...rest }) => {
    // filter value here? initialValue messes up all the selects
    let inputValue = getInputValue ? getInputValue(value, options, allowUnfoundOption) :  getSelectValue(value, options, allowUnfoundOption)
    return (
        <SelectInput
          {...rest}
          onChange={(v) => {onChange && onChange(v);}}
          value={inputValue}
          cache={false}
          isLoading={!options}
          options={options}
          isDisabled={isDisabled}
          isClearable={clearable}
          isMulti={multi}
          menuPortalTarget={menuPortalTarget}
          styles={ isError ? {...defaultSelectStyles, ...errorStyles} : defaultSelectStyles }
          onBlurResetsInput={false}
          onSelectResetsInput={false}
        />
    )
  }

export const SelectFieldFromDefault = ({
    name,
    meta, 
    input,
    options,
    isError,
    menuPortalTarget=document.body,
    multi=true,
    onChange=null,
    isDisabled=false,
    isLoading=false,
    clearable=true,
    formatOption=null,
    getInputValue=null,
    allowUnfoundOption=false,
    ...rest }) => {
      // filter value here? initialValue messes up all the selects
      let inputValue = getInputValue ? getInputValue(input?.value, options, allowUnfoundOption) :  getSelectValue(input?.value, options, allowUnfoundOption)
      return (
          <SelectInput
            {...rest}
            onChange={(v) => {onChange && onChange(v);input.onChange(v)}}
            value={inputValue}
            cache={false}
            isLoading={isLoading || !options}
            options={(inputValue && (inputValue?.unfound && allowUnfoundOption)) ? [...(options || []), inputValue] : options}
            isDisabled={isDisabled}
            isClearable={clearable}
            isMulti={multi}
            menuPortalTarget={menuPortalTarget}
            styles={ isError ? {...defaultSelectStyles, ...errorStyles} : defaultSelectStyles }
            onBlur={input.onBlur}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
          />
      )
    }
  
  /**
   * Select from a list of options.
   * Automitaclly creates {value: val, label: label} from given array of string options
   */
  export const SelectFieldFromList = ({
    name,
    meta, 
    input,
    options,
    selectName, 
    isError,
    menuPortalTarget=document.body,
    multi=true,
    onChange=null,
    disabled=false,
    clearable=true,
    formatOption=null,
    selectStyles={},
    ...rest }) => {
  
      // format option with translation in data.json
      const _formatOption = (val) => {
        return { 
          value: val, 
          label: capitalize(val) //TODO add translations how?
        }
      }
  
      // used to select value for onChange
      const retrieveValues = (vals) => {
        
        if (!multi) {
          onChange && onChange(vals && vals.value)
          return vals && vals.value;
        }
        const returnVals = (vals || []).map( selectVal => selectVal.value)
        onChange && onChange(returnVals)
        return returnVals
      }
  
      // return options
      const _options = () => {
        return (options || []).length > 0
          ? options.map(elem => _formatOption(elem))
          : [];
      }
  
      // get value(s) in {value, label} format
      const _value = () => {
        if (!multi) return input.value && _formatOption(input.value);
        if ((input.value || []).length > 0){
          return input.value.map( item => _formatOption(item))
        } else {
          return []
        }
      }
  
      return (
        <FormControl id={name} key={`MultipleSelectAsyncField_${name}`}>
          <SelectInput
            {...rest}
            onChange={inputVal => {onChange && onChange();input.onChange(retrieveValues(inputVal))} }
            value={_value()}
            cache={false}
            isLoading={!options}
            options={_options()}
            isDisabled={disabled}
            isClearable={clearable}
            menuPortalTarget={menuPortalTarget}
            isMulti={multi}
            styles={ isError ? errorStyles : {...defaultSelectStyles, ...selectStyles} }
            onBlur={input.onBlur}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
          />
        </FormControl>
      )
    }
  
  export const SelectFieldFromListRaw = ({
      name, 
      value,
      options,
      selectName, 
      isError,
      inputRef=null,
      multi=true,
      onChange=null,
      menuPortalTarget=document.body,
      initTranslations=null,
      disabled=false,
      clearable=true,
      formatOption=null,
      selectStyles={},
      ...rest }) => {
  
        const translations = initTranslations ?  initTranslations : {
          'appt_reminder':'Appt Reminder: Send x days before appointment.',
          'appt_scheduled': 'Appt Confirmation: Send when appointment is created.',
          'pay_reminder': 'Payment Reminder: Send when payment is required.',
          'pay_confirmed': 'Payment Confirmation: Send when payment has been processed.',
          'ship_confirmed': 'Shipped: Send when product has been shipped.',
          'other': 'Other'
        }
  
        // format option with translation in data.json
        const _formatOption = (val) => {
          return { 
            value: val, 
            label: capitalize(translations[val] || val)
          }
        }
    
        // used to select value for onChange
        const retrieveValues = (vals) => {
          
          if (!multi) {
            onChange && onChange(vals && vals.value)
            return vals && vals.value;
          }
          const returnVals = (vals || []).map( selectVal => selectVal.value)
          onChange && onChange(returnVals)
          return returnVals
        }
    
        // return options
        const _options = () => {
          return (options || []).length > 0
            ? options.map(elem => _formatOption(elem))
            : [];
        }
    
        // get value(s) in {value, label} format
        const _value = () => {
          if (!multi) return value && _formatOption(value);
          if ((value || []).length > 0){
            return value.map( item => _formatOption(item))
          } else {
            return []
          }
        }
    
        return (
          <FormControl id={name} key={`MultipleSelectAsyncFieldRaw_${name}`}>
            <SelectInput
              {...rest}
              onChange={inputVal => {onChange(retrieveValues(inputVal))} }
              value={_value()}
              inputRef={inputRef}
              cache={false}
              isLoading={!options}
              options={_options()}
              isDisabled={disabled}
              isClearable={clearable}
              menuPortalTarget={menuPortalTarget}
              isMulti={multi}
              styles={ isError 
                ? {...defaultSelectStyles, ...selectStyles, ...errorStyles} 
                : {...defaultSelectStyles, ...selectStyles} 
              }
              onBlurResetsInput={false}
              onSelectResetsInput={false}
            />
          </FormControl>
        )
      }