

import { useQuery } from 'react-query';
import { queryConfig, queryClient } from 'src/_libs/react-query';
import { toString } from 'lodash';

import { fetchDentistDetails } from './services';

export const QUERY_KEY_NAME = 'Admin_DentistDetails';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (dentistId) => {
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    //const searchParams = new URLSearchParams(paramsObj)
    return fetchDentistDetails(dentistId)
}

export const updateDentistDetails = (dentistId, newData) => {
    queryClient.setQueryData([QUERY_KEY_NAME, {id: toString(dentistId)}], (oldData) => {
        return {
            ...oldData,
            ...newData
        }
    })
}

export const useDentistDetails = ({ dentistId }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!dentistId,
        queryKey: [QUERY_KEY_NAME, {id: toString(dentistId)}],
        queryFn: () => queryRequest(dentistId), // in brackets because im parsing the object in queryRequest
      });
}