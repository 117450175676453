import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchTreatmentPlanCollection as getQuery } from './services';
import { QUERY_KEY_NAMES } from './constants';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_TREATMENTPLAN_COLLECTION;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const getQueryParams = ({q="", status="", type="", orderBy="", offset=0, pg_sz=10}) => {
    return {
      q, status, type, orderBy, offset, pg_sz
    }
}

export const queryRequest = async (queryParams) => {
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    const searchParams = new URLSearchParams(paramsObj)
    return getQuery(searchParams)
}

export const useTreatmentPlanCollection = ({ type="", status="", q="", orderBy="", offset=0, pg_sz=10 }) => {
  const queryParameters = getQueryParams({q, status, type, orderBy, offset, pg_sz})
    return useQuery({
        ...newQueryConfig,
        queryKey: [QUERY_KEY_NAME, queryParameters],
        queryFn: () => queryRequest(queryParameters), // in brackets because im parsing the object in queryRequest
      });
}