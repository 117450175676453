import React from 'react';
import { Icon } from '@chakra-ui/react';

export const PlusIcon = (props) => {

  const iconWidth = props?.width || 12;
  const iconHeight = props?.height || 12;
  return (
    <Icon 
      viewBox="0 0 12 12" 
      color={'#405159'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
      <path d="M6 0V12" stroke="currentColor" strokeWidth="2" />
      <path d="M12 6H0" stroke="currentColor" strokeWidth="2" />

    </Icon>
  );
};
