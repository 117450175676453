import React from 'react';
import { Icon } from '@chakra-ui/react';

const DentistUserIcon = (props) => {
  const iconWidth = props?.width || 18;
  const iconHeight = props?.height || 18;
  return (
    <Icon 
      viewBox="0 0 18 18" 
      color={'#3E5159'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M9.00011 0.959229C7.07622 0.959229 5.5166 2.51885 5.5166 4.44273C5.5166 6.3675 7.07622 7.92712 9.00011 7.92712C10.924 7.92712 12.4836 6.3675 12.4836 4.44273C12.4836 2.51885 10.924 0.959229 9.00011 0.959229ZM9.00012 1.84336C10.4333 1.84336 11.5995 3.00954 11.5995 4.44272C11.5995 5.8768 10.4333 7.04297 9.00012 7.04297C7.56693 7.04297 6.40075 5.8768 6.40075 4.44272C6.40075 3.00954 7.56693 1.84336 9.00012 1.84336Z" 
            fill="currentColor"/>
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M13.6693 8.92712H4.33099C2.94996 10.3081 2.17634 11.0827 0.794434 12.4637V17.0409H17.2058V12.4637C15.8248 11.0827 15.0503 10.3081 13.6693 8.92712ZM13.3032 9.81127L16.3217 12.8297V16.1567H1.67856V12.8297L4.69789 9.81127H13.3032Z" 
            fill="currentColor"/>

    </Icon>
  );
};

export default DentistUserIcon;