import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';
import { QUERY_KEY_NAMES } from './constants';

import { searchMembership as getQuery } from './services';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.SEARCH_MEMBERSHIPS;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const getQueryParams = ({membership=""}) => {
    return {
        membership, pg_sz: 1
    }
}

export const queryRequest = async (queryParams) => {
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    const searchParams = new URLSearchParams(paramsObj)
    return getQuery(searchParams)
}

export const useMembershipSearch = ({ membership=""}) => {
  const queryParameters = getQueryParams({membership})
    return useQuery({
        ...newQueryConfig,
        enabled: !!membership,
        queryKey: [QUERY_KEY_NAME, queryParameters],
        queryFn: () => queryRequest(queryParameters), // in brackets because im parsing the object in queryRequest
      });
}