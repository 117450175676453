


import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchUserFavoriteDentists } from './services';
import { toString } from 'lodash';

export const QUERY_KEY_NAME = 'Admin_PatientUserDetailsFavoriteDentists';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (userId) => {
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    //const searchParams = new URLSearchParams(paramsObj)
    return fetchUserFavoriteDentists(userId)
}

export const usePatientUserDetailsFavoriteDentists = ({ userId }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!userId,
        queryKey: [QUERY_KEY_NAME, {id: toString(userId)}],
        queryFn: () => queryRequest(userId), // in brackets because im parsing the object in queryRequest
      });
}