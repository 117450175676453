import { useMutation } from 'react-query';
import { queryClient } from 'src/_libs/react-query';

import { toString } from 'lodash';
import { createTreatmentPlan } from './services';
import { QUERY_KEY_NAMES } from './constants';


const createQuery = async ({ data, clinic }) => {
    console.log("createQuery data", data);
    return createTreatmentPlan(data, clinic);
}

const onSuccess = async (data) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_TREATMENTPLAN_DETAILS, {id: toString(data?.unique_id)}], () => {
        const newData =  {
            treatment_plan: {
                data
            }
        }
        return newData
    });
}

export const useCreateTreatmentPlan = ({ clinic }) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.CREATE_TREATMENTPLAN,
        onSuccess: (data) => onSuccess(data),
        mutationFn: (data) => {        
            return createQuery({ data, clinic });
        },
    });
}