import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchSelectOptions } from './_services';

export const QUERY_KEY_NAME = 'Dentist_SelectOptions';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (select_option) => {
    return fetchSelectOptions(select_option)
}

export const useDentistSelectOptions = ({ option="" }) => {
    let queryName = null
    if( option == "request_statuses"){
        queryName = `${QUERY_KEY_NAME}_clinicRoles`
    } else if (option == "request_types"){
        queryName = `${QUERY_KEY_NAME}_countries`
    } else if( option == "user_roles"){
        queryName = `${QUERY_KEY_NAME}_userRoles`
    } else if(option == "genders"){
        queryName = `${QUERY_KEY_NAME}_genders`
    }
        

    return useQuery({
        ...newQueryConfig,
        queryKey: [queryName, {}],
        queryFn: () => queryRequest(option), // in brackets because im parsing the object in queryRequest
      });
}