export const numberToFixedDecimal = (input, decimalPlaces=2) => {
    const num = typeof input === "number" ? input : parseFloat(input); // Convert the string to a number

    if (isNaN(num)) {
        return "";
    }

    // Round the number based on the required decimal places
    return decimalPlaces === 0
        ? Math.round(num).toString() // Round to 0 decimals
        : num.toFixed(2); // Round to 2 decimals
    };

export const truncateString = (str, startChars = 4, endChars = 4) => {
    if (str.length <= startChars + endChars) {
        return str; // If the string is shorter than the total desired length, return it as is
    }
    return `${str.substring(0, startChars)}...${str.substring(str.length - endChars)}`;
};

export const getDiscount = (item) => {
    // calculate item.average_price vs item.base_price
    if (item?.fee_schedule && item?.cost_amount){
        return Math.round((item?.fee_schedule?.average_price - item?.cost_amount) / item?.fee_schedule.average_price * 100);
    } else {
        return null;
    }
}

export const getCurrencySymbol = (currency, locale='en') => {
    // Format a dummy number to extract the currency symbol
    const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        currencyDisplay: "narrowSymbol",
    });
    const parts = formatter.formatToParts(1);
    return parts.find((part) => part.type === "currency")?.value || "";
};
