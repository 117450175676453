import { API_URLS } from "src/_config";
import { authHeader, handleResponse } from 'src/_helpers';

export function completeTreatmentPlan(id, data, clinic){
  const requestUrl = API_URLS.completeTreatmentPlan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    //body: JSON.stringify({...data, clinic}),
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{id}", id);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function startTreatmentPlan(id, data, clinic){
  const requestUrl = API_URLS.startTreatmentPlan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    //body: JSON.stringify({...data, clinic}),
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{id}", id);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function rejectTreatmentPlan(id, data, clinic){
  const requestUrl = API_URLS.rejectTreatmentPlan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    //body: JSON.stringify({...data, clinic}),
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{id}", id);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function archiveTreatmentPlan(id, data, clinic){
  const requestUrl = API_URLS.archiveTreatmentPlan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    //body: JSON.stringify({...data, clinic}),
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{id}", id);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function cancelTreatmentPlan(id, data, clinic){
  const requestUrl = API_URLS.cancelTreatmentPlan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    //body: JSON.stringify({...data, clinic}),
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{id}", id);
  if (clinic) {
    url = url + `?clinic=${clinic}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateTreatmentPlan(id, data, clinic) {
    const requestUrl = API_URLS.updateTreatmentPlan;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      //body: JSON.stringify({...data, clinic}),
      body: JSON.stringify(data),
    };
    let url = requestUrl.url.replace("{id}", id);
    if (clinic) {
      url = url + `?clinic=${clinic}`;
    }
    return fetch(url, requestOptions).then(handleResponse);
  }

export function createTreatmentPlan(data, clinic) {
    const requestUrl = API_URLS.createTreatmentPlan;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      //body: JSON.stringify({...data, clinic}),
      body: JSON.stringify(data),
    };
    let url = requestUrl.url;
    if (clinic) {
      url = url + `?clinic=${clinic}`;
    }
    return fetch(url, requestOptions).then(handleResponse);
  }

export function searchMembership(urlSearchParams) {
    const requestUrl = API_URLS.searchMembership;

    const requestOptions = {
      method: requestUrl.method,
      contentType: "application/json",
      headers: { ...authHeader() },
    };
    let url = requestUrl.url + `?${urlSearchParams}`;
    return fetch(url, requestOptions).then(handleResponse);
  }
  
  export function fetchTreatmentPlanDetails(id) {
    const requestUrl = API_URLS.fetchTreatmentPlanDetails;
    const requestOptions = {
      method: requestUrl.method,
      contentType: "application/json",
      headers: { ...authHeader() },
    };
    let url = requestUrl.url.replace("{id}", id);
    return fetch(url, requestOptions).then(handleResponse);
  }


  export function fetchMembershipDetails(id) {
    const requestUrl = API_URLS.fetchMembershipDetails;
    const requestOptions = {
      method: requestUrl.method,
      contentType: "application/json",
      headers: { ...authHeader() },
    };
    let url = requestUrl.url.replace("{id}", id);
    return fetch(url, requestOptions).then(handleResponse);
  }


  export function fetchFeeScheduleCollection(urlSearchParams) {
    const requestUrl = API_URLS.fetchFeeScheduleCollection;
    const requestOptions = {
      method: requestUrl.method,
      contentType: "application/json",
      headers: { ...authHeader() },
    };
    let url = requestUrl.url + `?${urlSearchParams}`;
    return fetch(url, requestOptions).then(handleResponse);
  }



  export function fetchTreatmentPlanCollection(urlSearchParams) {
    const requestUrl = API_URLS.fetchTreatmentPlanCollection;
    const requestOptions = {
      method: requestUrl.method,
      contentType: "application/json",
      headers: { ...authHeader() },
    };
    let url = requestUrl.url + `?${urlSearchParams}`;
    return fetch(url, requestOptions).then(handleResponse);
  }

