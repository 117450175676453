import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';
const WrappedPage = styled(PerfectScrollBar)`
  min-height: 100vh;
  display: grid;
  grid-gap: 10px;
  padding-top: 102px; /* height of the nav bar 72px + extra for content */
  margin-left: 90px; /* width of the desktop sidebar */
  padding-right: 20px;
  padding-left: 60px;
  background-color: white;

  @media (max-width: 768px) {
    width: 100%; /* Full width for mobile */
    margin-left: 0; /* Remove space for the sidebar */
    margin-bottom: 60px; /* Add space for the bottom mobile sidebar */
    padding-left: 20px; /* Adjust padding for mobile */
    padding-right: 20px; /* Keep the padding consistent */
    overflow-x: !hidden; /* Allow for horizontal scrolling */
  }
`;
export {WrappedPage};
