

import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';
import { toString } from 'lodash';

import { fetchMembershipDetails as getQuery } from './services';
import { QUERY_KEY_NAMES } from './constants';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_MEMBERSHIP_DETAILS;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (id) => {
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    //const searchParams = new URLSearchParams(paramsObj)
    return getQuery(id)
}

export const useMembershipDetails = ({ id }) => {
    return useQuery({
        ...newQueryConfig,
        enabled: !!id,
        queryKey: [QUERY_KEY_NAME, {id: toString(id)}],
        queryFn: () => queryRequest(id), // in brackets because im parsing the object in queryRequest
      });
}