import React from 'react';
import { Icon } from '@chakra-ui/react';

export const InfoIcon = (props) => {
  const iconWidth = props?.width || 20;
  const iconHeight = props?.height || 20;
  const color = props?.color || '#2E3D44';
  return (
    <Icon 
      viewBox="0 0 20 20" 
      fill={'none'}
      {...props}
      color={color} 
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <circle cx="10" cy="10" r="6.5" stroke="currentColor"/>
        <path d="M10 9V14" stroke="currentColor"/>
        <path d="M10 7V8" stroke="currentColor"/>

    </Icon>
  );
};