import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-intl-redux';
import { PubNubProvider } from "pubnub-react";
import { history, store, pubnub } from '../_helpers';
import { createPubNubListener } from "pubnub-redux";
import Modal from 'react-modal';
import { ChakraProvider, Spinner } from '@chakra-ui/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider, useAuth } from 'src/_libs/auth';
import './App.css';
import '../_css/index.css';
import { Box } from '@chakra-ui/react';
import { GlobalDebug } from './remove-consoles';

import 'moment/locale/th';
import 'moment/locale/ja';
import 'moment/locale/ko';

import {  PrivateRouteWithChildren } from 'src/_components/PrivateRoute';
// import the Page Components
import {Sidebar} from '../PageComponents/Sidebar';
import {NavigationBar} from '../PageComponents/NavigationBar';
import {AlertDialog} from '../AlertDialog';
import {LoginPage} from '../LoginPage';
import {Dashboard} from '../Dashboard';
import {RequestListPage} from '../RequestListPage';
import { PatientListPage } from 'src/Patients/PatientListPage';
import {PatientDetailsPage} from '../PatientDetailsPage';
import {RequestDetailPage } from '../RequestDetailPage';
import {OpenRequestsPage} from '../OpenRequestsPage';
import { ChatPage } from '../ChatPage';
//import { VideoChatPage } from '../VideoChatPage';
import { AppointmentsCalendar } from '../Appointments';
import { SettingsPage } from '../SettingsPage';
// TODO 3: import {PatientPage} from './PatientPage';
// TODO 4: import {NoMatchPage} from './NoMatchPage';
//

import { TestTeethChart } from 'src/Reports/components/TestTeethChart';

// routes
// import ReportRoutes from 'src/Reports/routes';
import { ReportForRequestPage } from 'src/Reports/routes/ReportForRequestPage';
import { Routes as TreatmentPlansRoutes } from 'src/TreatmentPlans/routes';
import { Routes as AdminRoutes} from 'src/AdminPage/routes';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { theme } from '../_config/theme';
import { queryClient } from 'src/_libs/react-query';
import { PasswordResetPage } from 'src/LoginPage/ResetPasswordPage';


const leaveApplication = () => {
  // This is required to show the current user leave immediately rather than
  // wating for the timeout period
  pubnub.unsubscribeAll();
};

Modal.setAppElement('#app')


const RedirectUserToHome = () => {
  const { user } = useAuth();
  if (user?.is_staff){
    return <Redirect to={'requests'} />
  }
  if (((user?.apps || []).includes('memberships'))){
      return <Redirect to={'treatmentPlans'} />
  } else { //includes('open_requests') 
      return <Redirect to={'requests'} />
  }
}

export const App = () => {
  const scrollRef = React.useRef(null);

  React.useEffect(() => {
    pubnub.addListener(createPubNubListener(store.dispatch));
    window.addEventListener("beforeunload", leaveApplication);
    
    // eslint-disable-next-line no-undef
    (process.env.NODE_ENV === "production") && GlobalDebug(false);

    return () => {
      window.removeEventListener("beforeunload", leaveApplication);
    }
  }, []);

  // TODO add a OpenRequests Page for Admins. so that they can assign
  // eslint-disable-next-line no-unused-vars
  const handleUpdateChildSize = () => {
    if (scrollRef.current){
      scrollRef.current.updateScroll();
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleScrollToTop = () => {
    if (scrollRef.current){
      scrollRef.current.scrollTop = 0;
    }
  }

  return (
    <ChakraProvider theme={theme}>
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Spinner size="xl" />
          </div>
        }
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <Provider store={store}>
              <PubNubProvider client={pubnub}>
                <ConnectedRouter history={history}>
                  <AlertDialog />
                  <NavigationBar />
                  <PerfectScrollbar ref={scrollRef}>
                  <Box
                    height={'100vh'}
                  >
                    <Sidebar />
                    <Switch >
                      {/*<Route path="/videochat" strict={false}  component={VideoChatPage} />*/}
                      <Route path="/login" strict={true} component={LoginPage} />
                      <Route path="/reset" strict={true} component={PasswordResetPage} />
                      <Route path="/testimage" strict={true} component={TestTeethChart} />
                      <PrivateRouteWithChildren path={`/dashboard`} >
                          <Dashboard />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/openrequests`} >
                          <OpenRequestsPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/chats`} >
                          <ChatPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/reports/request/:requestId`} >
                          <ReportForRequestPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/request/:request_id`} >
                          <RequestDetailPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/patients/:patient_uid`} >
                          <PatientDetailsPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/settings/:settingsId`} >
                          <SettingsPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/settings`} >
                          <SettingsPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/patients`} >
                          <PatientListPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/cal`} >
                          <AppointmentsCalendar />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/adm/*`} >
                          <AdminRoutes />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/treatmentPlans*`} >
                          <TreatmentPlansRoutes />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/requests`} >
                        <RequestListPage />
                      </PrivateRouteWithChildren>
                      <PrivateRouteWithChildren path={`/`} strict={true}>
                          <RedirectUserToHome />
                      </PrivateRouteWithChildren>
                      
                    </Switch>
                  </Box>
                  </PerfectScrollbar>
                </ConnectedRouter>
              </PubNubProvider>
            </Provider>
          </AuthProvider>
        </QueryClientProvider>
      </React.Suspense>
    </ChakraProvider>
  );
}
