import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, SimpleGrid, VStack, Flex, Text, Input, Checkbox, Button } from '@chakra-ui/react';
import { useMembershipSearch } from '../api/searchMembership';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageHeader } from '../components/PageHeader';
import { useCreateTreatmentPlan } from '../api/createTreatmentPlan';
import { useAppConfig } from 'src/App/state/appConfig';


const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "short",
        day: "numeric"
    }).format(new Date(date));
};

function getAge(date) {
    if (!date) return '';
    const birthDate = new Date(date);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust if the current date is before the birth date in the current year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}


const MemberItem = ({membershipNumber, membership, isSelected, onSelectedMember}) => {

    const LABEL_TEXT_PROPS = {
        color: '#9B9B9B',
        mr: "4px",
        textAlign: 'left'
    }

    const NOT_SELECTED_PROPS = {
        boxShadow: '0px 1px 6px 0px rgba(200, 201, 211, 0.60)',
        bg: '#FFF'
    }

    return (
        <Box
            w={'full'}
            key={membership.unique_id}
            borderRadius={'20px'}
            px={[6]}
            py={[4]}
            {...NOT_SELECTED_PROPS}
            onClick={() => onSelectedMember(isSelected ? null : membership.unique_id)}
            _hover={{cursor: 'pointer'}}
        >
            <SimpleGrid columns={2} spacing={'10px'} templateColumns={"1fr 7fr"}>
                <Flex align={'center'}>
                    <Checkbox
                        isChecked={isSelected}
                        onChange={(e) => onSelectedMember(e.target.checked ? null : membership.unique_id)}
                    /> 
                </Flex>
                <Box>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            {membership.is_default ? (
                                <FormattedMessage
                                id="membershipSearch.label.isPrimary"
                                defaultMessage='Primary'
                                />
                            ) : (
                                <FormattedMessage
                                    id="membershipSearch.label.isDependent"
                                    defaultMessage='Dependent'
                                />
                            )}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="membershipSearch.label.membershipId"
                                defaultMessage='ID'
                            />
                            {": "}
                        </Text>
                        <Text>
                            {membershipNumber}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="membershipSearch.label.fullName"
                                defaultMessage='Name'
                            />
                            {": "}
                        </Text>
                        <Text>
                            <FormattedMessage
                                id="format.fullName"
                                defaultMessage="{givenName} {familyName}"
                                values={{ givenName: membership.first_name, familyName: membership.last_name }}
                            />
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="membershipSearch.label.dateOfBirth"
                                defaultMessage='Date of Birth'
                            />
                            {": "}
                        </Text>
                        <Text>
                            {membership?.date_of_birth ? formatDate(membership.date_of_birth) : '-'}
                            {membership?.date_of_birth ? ` ( ${getAge(membership.date_of_birth)} )` : ''}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="membershipSearch.label.gender"
                                defaultMessage='Gender'
                            />
                            {": "}
                        </Text>
                        <Text>
                            {membership.gender}
                        </Text>
                    </Flex>
                    {
                        !membership.relationship?.is_default && (
                            <Flex>
                                <Text {...LABEL_TEXT_PROPS}>
                                    <FormattedMessage
                                        id="membershipSearch.label.dependent"
                                        defaultMessage='Dependent'
                                    />
                                    {": "}
                                </Text>
                                <Text>
                                    {membership.relationship}
                                </Text>
                            </Flex>
                        )
                    }
                </Box>
            </SimpleGrid>
        </Box>
    )
}

export const MembershipSearch = () => {
    const [search, setSearch] = React.useState('');
    const history = useHistory();
    const clinic = useAppConfig(state => state.clinic?.id); // current selected clinic from app config
    const { formatMessage } = useIntl();
    const [ selectedMember, setSelectedMember ] = React.useState(null);
    //const setCurrentMembership = useMembershipStore((state) => state.setCurrentMembership);
    const createTreatmentPlanMutation = useCreateTreatmentPlan({clinic});
    const [ membership, setMembership ] = React.useState(null);
    const { data, isLoading, isFetching, isError } = useMembershipSearch({membership: membership});
    const LABEL_TEXT_PROPS = {
        color: '#9B9B9B',
        mr: "4px"
    }

    const handleConfirmIdentity = async (membershipId, profile) => {
        try{
            const resp = await createTreatmentPlanMutation.mutateAsync({membership: membershipId, user_profile: profile});
            //setCurrentMembership(membership, profile);
            history.push(`/treatmentPlans/${resp.unique_id}`);
        } catch (err){
            console.error(err);
            return;
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setMembership(search);
            // Perform your desired action here
        }
    };


    return (
        <Box>
            <PageHeader page="membershipSearch" />
            <Flex w={'full'} align={'center'} mt={['20px', '40px']} direction={['column', 'row']}>
                <Input type="text" value={search} onChange={(e) => setSearch(e.target.value)} 

                    onKeyDown={handleKeyDown}
                    placeholder={formatMessage({id: "membershipSearch.input.placeholder", defaultMessage: "Enter Membership Id"} )} 
                />
                <Box w={['full', 'auto']} ml={['0', '30px']} mt={['10px', 0]}>
                    <Button
                        w={['full', 'auto']}
                        onClick={() => {
                            setMembership(search);
                        }}  
                        isLoading={isLoading || isFetching}
                    >
                        <FormattedMessage 
                            id="membershipSearch.button.search"
                            defaultMessage='Search'
                        />
                    </Button>
                </Box>
            </Flex>
            <Box mt={'50px'} px={[0, '20px']}>
                { (isError && (search || "").length > 0) ? (
                    <Flex textAlign={'left'} justify={'flex-start'}>
                        <Text color={'#9B9B9B'}>
                            <FormattedMessage
                                id="membershipSearch.noResults"
                                defaultMessage='No results found for'
                            />
                        </Text>
                        <Text color={'#9B9B9B'}>
                           { `"${membership}"`}
                        </Text>
                    </Flex>
                ) : ((data?.membership?.members || []).length < 1) ? (
                    <Flex textAlign={'left'} justify={'flex-start'}>
                        <Text color={'#9B9B9B'}>
                            <FormattedMessage
                                id="membershipSearch.noResults.initial"
                                defaultMessage='Enter Membership ID to find a member'
                            />
                        </Text>
                    </Flex>
                ) : (
                    <Box>
                        <VStack w={'full'} spacing={['15px']}>
                            {(data?.membership?.members || []).map((membership) => (
                                <MemberItem 
                                    key={membership.unique_id}
                                    membershipNumber={data?.membership?.membership_number}
                                    membership={membership} 
                                    onSelectedMember={setSelectedMember} 
                                    isSelected={selectedMember === membership.unique_id}
                                />
                            ))}
                        </VStack>
                        <Box mt={'20px'}>
                            <Text {...LABEL_TEXT_PROPS} textAlign={'left'}>
                                <FormattedMessage 
                                    id={'membershipSearch.confirm.message'}
                                    defaultMessage='**Please confirm that the membership information matches**'
                                />
                            </Text>
                            <Flex w={'full'} justify={'flex-end'} mt={'10px'}>
                                <Button
                                    onClick={() => handleConfirmIdentity(data?.membership?.unique_id, selectedMember)}
                                    isDisabled={!selectedMember}    
                                >
                                    <FormattedMessage
                                        id="membershipSearch.confirm.button.confirm"
                                        defaultMessage='Confirm Identity'
                                    />
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}