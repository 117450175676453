export const QUERY_KEY_NAMES = {
    SEARCH_MEMBERSHIPS: 'Dentist_MembershipSearch',
    GET_MEMBERSHIP_DETAILS: 'Dentist_MembershipDetails',
    GET_TREATMENTPLAN_COLLECTION: 'Dentist_TreatmentPlanCollection',
    CREATE_TREATMENTPLAN: 'Dentist_CreateTreatmentPlan',
    UPDATE_TREATMENTPLAN: 'Dentist_UpdateTreatmentPlan',
    GET_TREATMENTPLAN_DETAILS: 'Dentist_TreatmentPlanDetails',
    GET_FEESCHEDULE_COLLECTION: 'Dentist_FeeScheduleCollection',
    UPDATE_TREATMENTPLAN_STATUS_CANCEL: 'Dentist_UpdateTreatmentPlanStatusCancel',
    UPDATE_TREATMENTPLAN_STATUS_COMPLETE: 'Dentist_UpdateTreatmentPlanStatusComplete',
    UPDATE_TREATMENTPLAN_STATUS_INPROGRESS: 'Dentist_UpdateTreatmentPlanStatusInProgress',
    UPDATE_TREATMENTPLAN_STATUS_ARCHIVE: 'Dentist_UpdateTreatmentPlanStatusArchive',
    UPDATE_TREATMENTPLAN_STATUS_REJECT: 'Dentist_UpdateTreatmentPlanStatusReject',
}