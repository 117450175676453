import styled from 'styled-components';
import {
  InitialsAvatar,
  StyledForm,
  StyledInputLayout,
  StyledInput,
  StyledButton,
} from '../_css/styles';

export const StyledEditAvatar = styled.div`
  > input {
    display: none;
  }
  position: absolute;
  bottom: 0;
  text-align: center;
  font-family: Proxima Nova;
  text-shadow: 0 2px 5px black;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.8px;
  color: #ffffff;
  width: 100%;
  margin-bottom: ${(props) => (props.progress > 0 ? '5px' : '12px')};
  &:hover {
    cursor: pointer;
    cursor: ${(props) => (props.progress > 0 ? 'default' : 'pointer')};
  }

  /* Mobile styles */
  @media (max-width: 768px) {
    margin-bottom: ${(props) => (props.progress > 0 ? '2px' : '5px')};
    font-size: 11px;
  }

`;

export const StyledProfileAvatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100px;

  ${InitialsAvatar} {
    width: 100px;
    height: 100px;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100px;
  }

  /* Mobile styles */
  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
    ${InitialsAvatar} {
      width: 70px;
      height: 70px;
    }
    img {
      width: 70px;
      height: 70px;
    }
  }
`;

export const StyledMenuItem = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.8px;
  color: #000000;
  margin: 0 25px;
  padding-left: 15px;
  border-bottom: 1px solid #ebf2f5;
  display: flex;
  align-items: center;
  text-align: left;

  background-color: ${(props) => (props.selected ? '#EDF3F6' : '')};

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }

  span {
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  a {
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  a,
  a:hover,
  a:visited,
  a:active,
  a:focus {
    color: inherit;
    text-decoration: none !important;
    outline: none !important;
  }

  .clickItem {
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  svg {
    margin-left: 10px;
  }


  /* Mobile styles */
  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const StyledMenuList = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px;
  padding-right: 0;

  overflow: auto;

  .menuSubHeader {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    margin: 0 25px;
    color: #121719;
    padding-top: 30px;
    padding-bottom: 10px;
    text-align: left;
  }

  button {
    text-align: center;
  }


  /* Mobile styles */
  @media (max-width: 768px) {
    padding-left: 25px;
    padding-right: 25px;


    .menuSubHeader {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`;

export const StyledMenuHeader = styled.div`
  margin-bottom: 20px;
  padding: 25px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: fit-content;
  text-align: left;
  h2 {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #121719;
  }

  .image {
    height: 100px;
  }

  > div {
    margin-top: 30px;
    display: flex;

    &.loading {
      width: 100%;
      justify-content: center;
      height: 100px;
    }
  }

  .loadingCircle {
    width: 80px;
  }

  .image {
    position: relative;
  }

  .details {
    margin-left: 30px;
    text-align: left;
    .name {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: 0.8px;
      color: #000000;
    }
    .email {
      margin-top: 0px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.8px;
      color: #000000;
    }
    .link {
      margin-top: 15px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.8px;
      color: #000000;
      opacity: 0.3;
      a,
      a:hover,
      a:visited,
      a:active,
      a:focus {
        color: inherit;
        text-decoration: none !important;
        outline: none !important;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }
    }
  }


  /* Mobile styles */
  @media (max-width: 768px) {
    padding: 25px;


    > div {
      margin-top: 10px;
    }

    .image {
      height: 70px;
    }
    .details {
      .name {
        font-size: 24px;
      }
      .email { 
        font-size: 16px;
      }
    }

  }
`;

export const StyledMenuContent = styled.div``;

export const StyledProfile = styled.div`

  /* Mobile styles */
  @media (max-width: 768px) {
      .description {
        height: unset;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

`;

export const StyledNotifications = styled.div``;

export const StyledChangePassword = styled.div`
  ${StyledButton} {
    margin: 10px 0;
  }
`;

export const StyledSettingsPage = styled.div`
  min-height: 100vh;
  display: grid;
  grid-gap: 10px;
  padding-top: 102px;
  margin-left: 90px;
  padding-right: 20px;
  padding-bottom: 40px;
  background-color: white;
  padding-left: 60px;
  grid-auto-rows: max-content;
  text-align: left;

  ${StyledForm} {
    background: none;
    border: none;
    box-shadow: none;
    text-align: left;
    padding-left: 0;
    padding-top: 0;
  }

  ${StyledInputLayout} {
    align-items: flex-start;
  }

  ${StyledInput} {
    max-width: 400px;
  }
  .title {
    font-size: 36px;
    font-family: 'Proxima Nova Semibold';
    line-height: 30px;
    margin-bottom: 20px;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-gap: 30px;

    .menuColumn {
      display: grid;
      .menuSubHeader {
      }
    }

    .contentColumn {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 35px 0px 25px 25px;
      position: relative;
      h2 {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #121719;
        padding-bottom: 4px;
        border-bottom: 1px solid #ebf2f5;
        margin-right: 20px;
      }
      .successMessage {
        height: 35px;
        display: flex;
        align-items: center;
      }
    }
  }


  /* Mobile styles */
  @media (max-width: 768px) {
    padding-top: 20px; /* Adjust padding for mobile */
    margin-left: 0;    /* Remove left margin */
    padding-left: 20px; /* Adjust left padding */

    .wrapper {
      grid-template-columns: 1fr; /* Full-width columns for mobile */

      .menuColumn {
        display: block;
        width: 100%; /* Full width on mobile */
      }

      .contentColumn {
        width: 100%; /* Full width when shown */
        box-shadow: none; /* Simplify styles for mobile */
        border-radius: 0; /* Remove rounded corners for mobile */
        padding: 20px; /* Adjust padding for mobile */
      }
    }
  }
`;
